<template>
  <div v-show="false" id="singletransitpolicy">
    <div
      style="
        width: 960px;
        margin: 0 auto;
        padding: 40px;
        border: 5px solid #990100;
        position: relative;
      "
    >
      <div style="display: flex; justify-content: center; align-items: center">
        <img class="my-4" src="@/assets/certlogo.png" alt="" />
      </div>
      <h2 class="my-4" style="text-align: center; color: #990100">
        <b>IMPORTANT</b>
      </h2>

      <p style="font-size: 18px">
        This Policy and its Conditions should be examined, and if incorrect
        returned at once for alteration.
      </p>
      <p style="font-size: 18px">
        Every change affecting the risks insured by this Policy must be
        immediately advised to the Company. Failure to do this might result in
        the insurance ceasing to be of effect. The Policy is not transferable
        from the insured to any other person unless the Company’s written
        consent has been obtained.
      </p>
      <p style="font-size: 18px">
        <b
          >In the event of any loss or damage notice should be given IMMEDIATELY
          to:</b
        >
      </p>
      <h3 style="line-height: 1.5">
        CONSOLIDATED HALLMARK INSURANCE PLC, CORPORATE HEAD OFFICE: 266, IKORODU
        ROAD, OBANIKORO, LAGOS. <br />

        E-mail: info@chiplc.com<br />
        Website: www.chiplc.com <br />
        Customer Service Hotline: 0700CHINSURANCE <br />(070024467872623)
      </h3>
      <p style="font-size: 18px">
        followed by such further steps as are required by the Conditions of this
        Policy.
      </p>

      <h3 class="my-4" style="font-size: 20px; text-align: center">
        MARINE CARGO INSURANCE POLICY
      </h3>
      <p style="font-size: 18px">
        We the <b>Consolidated Hallmark Insurance Plc </b>hereby agree in
        consideration of the payment to us by or on behalf of the Assured of the
        premium specified in the schedule to insure against loss, damage,
        liability or expenses in the manner hereinafter provided
      </p>
      <br />
      <br />
      <br />
      <p>Signed for on behalf of Consolidated Hallmark Insurance Plc</p>

      <div class="my-5" style="display: flex; justify-content: space-between">
        <div>
          <!-- <p><b>................................</b></p>
                    <h3>EXAMIMED</h3> -->
        </div>
        <div>
          <img src="@/assets/signature1.png" />
          <p><b>.........................................</b></p>
          <h3>AUTHORIZED SIGNATORY</h3>
        </div>
      </div>

      <p>
        The Schedule must bear the signature of a person duly authorized to sign
        on behalf of the Assurers.
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 class="my-5" style="font-size: 20px; text-align: center">
        THE SCHEDULE
      </h3>
      <div>
        <div style="display: flex; justify-content: space-between">
          <p><b>Policy Number:</b> {{ singleBusiness.policyno }}</p>
          <p>
            <b> Date of Policy:</b>
            {{
              singleBusiness.startDate != null
                ? singleBusiness.startDate.split("T")[0]
                : ""
            }}
          </p>
        </div>
        <p><b>(To be quoted in all matters relating to this insurance)</b></p>
        <p><b>Assured:- </b>{{ singleBusiness.insuredName }}</p>
        <p><b>TIN No:- </b>{{ singleBusiness.tin }}</p>
        <div style="display: flex; justify-content: space-between">
          <p>
            <b> Ship or Vessel :</b> ANY APPROVED VESSEL <br /><br />
            <b>Means of conveyance :</b> {{ singleBusiness.conveyance }}
          </p>

          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <p>
              <b> Premium: </b>
              {{ parseInt(singleBusiness.premium).toLocaleString("en-US") }}
              Naira Only
            </p>
            <p><b>Rate:</b> {{ singleBusiness.premiumRate }}%</p>
          </div>
        </div>
        <p><b>Voyage:</b> {{ singleBusiness.voyageType }}</p>
        <div style="display: flex; justify-content: space-between">
          <p><b>Destination:</b></p>
          <p>
            <b>From: {{ singleBusiness.from }} </b>
          </p>
          <p>
            <b>To: {{ singleBusiness.to }}</b>
          </p>
        </div>
        <p>
          <b>Interest/ Description:</b> {{ singleBusiness.cargoDescription }} AS
          PER <b>PI NO:</b> {{ singleBusiness.policyno }} <b>DATED</b>
          {{
            singleBusiness.startDate != null
              ? singleBusiness.startDate.split("T")[0]
              : ""
          }}
        </p>
        <p>
          <b>Sum Insured (In Figures):</b>
          {{ parseInt(singleBusiness.sumInsured).toLocaleString("en-US") }}
          Naira Only
        </p>
        <p>
          <b>in words:</b>
          {{ insuredWords }} Naira Only
        </p>
        <div class="mt-5">
          <h3 class="text-center mb-4"><b>Conditions of Insurance</b></h3>
          <div>
            <p>
              SUBJECT TO NO LOSS AS AT
              {{
                singleBusiness.startDate != null
                  ? singleBusiness.startDate.split("T")[0]
                  : ""
              }}
            </p>
            <p>SUBJECT TO STANDARD CONDITIONS FOR CARGO CONTRACTS</p>
            <p>SUBJECT TO NIGERIAN CLASSIFICATION CLAUSE</p>
            <p>SUBJECT TO CANCELLATION WARRANTY</p>
            <p>SUBJECT TO CLAIM NOTIFICATION CLAUSE</p>
            <p>WARRANTED GOODS BE PROFESSIONALLY PACKED</p>
            <p>
              WARRANTED NO MIDSTREAM DISCHARGE OR TRANSSHIPMENT WITH OUT THE
              CONSENT OF THE INSURER.
            </p>
            <p>SUBJECT TO EXCESS OF 10% OF CLAIM</p>
            <p>SUBJECT TO CONVEYING VESSEL WARRANTY</p>
            <p>WARRANTED SUPERVISORY DISCHARGE OF CARGO BY</p>
            <p>CONSOLIDATED HALLMARK INSURANCE PLC APPOINTED SURVEYOR.</p>
            <p>SUBJECT TO PORT DELAY CLAUSE</p>
            <p>SUBJECT TO INSTITUTE DANGEROUS DRUG CLAUSE</p>
            <p v-if="singleBusiness.voyageType == 'Port To Warehouse'">
              SUBJECT TO 21 DAYS CLEARANCE WARRANTY
            </p>
            <p v-if="singleBusiness.voyageType == 'Port To Port'">
              COVER TERMINATES IMMEDIATELY AFTER DISCHARGE OF CARGO FROM THE
              CARRYING VESSEL AT THE PORT
            </p>
            <p style="margin-top: 25px">
              Excluding Rust, Oxidation, Discoloration, Electrical & Mechanical
              Derangement, Water Damage except (Sea, River and Lake Water.),
              Contamination and Caking, Denting & Twisting, Contamination,
              Infestation, & Caking, Ordinary Leakage, Ordinary Loss in Weight
              or Volume, Wear & Tear, contra-band goods such as, fire arms,
              narcotics, opium and other dangerous goods
            </p>
          </div>
        </div>
        <div>
          <h3 style="font-size: 20px; margin-bottom: 10px">
            INSTRUCTION FOR SURVEY
          </h3>
          <p>
            In the event of loss or damage which may involve a claim under this
            insurance, immediate notice of such loss or damage should be given
            to the ASSURER who may arrange for Survey if necessary.
          </p>
        </div>
        <div>
          <h3 style="font-size: 20px">CLAIMS</h3>
          <p>
            In case of any lawful claim hereto, it is agreed that the same shall
            be settled by the COMPANY or the Claims Settling Agent named herein
            upon surrender of the original Policy duly endorsed. This insurance
            shall be subject to English Law and Practice in so far as the
            English Law does not conflict with the Laws of the Federal Republic
            of Nigeria.
          </p>
        </div>
        <div class="my-5" style="display: flex; justify-content: space-between">
          <div>
            <!-- <p><b>................................</b></p>
                        <h3>EXAMIMED</h3> -->
          </div>
          <div>
            <h3>Signed on behalf of the Assurers.</h3>
            <img src="@/assets/signature1.png" />
            <p>
              <b
                >Extd........................................................</b
              >
            </p>
          </div>
        </div>
      </div>
      <h3 class="my-4" style="font-size: 20px">
        CLAUSES ATTACHING TO AND FORMING PART OF MARINE CARGO INSURANCE POLICY
        NO: {{ singleBusiness.policyno }} ISSUED IN THE NAME OF
        {{ user.broker }}.
      </h3>
      <h3 class="my-3" style="text-align: center">
        <span>1/01/2002 </span> NIGERIAN CLASSIFICATION CLAUSE
      </h3>
      <div>
        <h4 style="font-size: 18px">QUALIFYING VESSELS</h4>
        <p>
          <span style="padding-right: 16px"><b>1</b></span
          >. This insurance and the marine transit rates as agreed in the policy
          or open cover apply only to cargoes and / or interests carried by
          mechanically self propelled of steel construction classed with a
          Classification Society which is:
        </p>
        <p style="padding-left: 30px">
          1.1 a Member or Associate Member of the International Association of
          Classification societies (IACS), or a National Flag society as defined
          in Clause 4 below, but only where the vessel is engaged exclusively in
          the coastal trading of that nation (including trading on an
          inter-island route within an archipelago of which that nation form
          part).
        </p>
      </div>
      <div>
        <h4 style="font-size: 18px">AGE LIMITATION</h4>
        <p>
          <span style="padding-right: 16px"><b>2</b></span
          >. Cargoes and / or interests carried by Qualifying Vessels (as
          defined above) which exceed the following age limits will be insured
          on the policy or open cover conditions.
        </p>
        <p style="padding-left: 30px">
          Bulk or combination carriers over 10 years of age or other vessels
          over 15 years of age unless they:
        </p>
        <p style="padding-left: 30px">
          <span>(a)</span> Member or Associate Member of the International
          Association of Classification societies (IACS), or a National Flag
          society as defined in Clause 4 below, but only where the vessel is
          engaged exclusively in the coastal trading of that nation (including
          trading on an inter-island route within an archipelago of which that
          nation form part).
        </p>
        <p style="padding-left: 30px">
          <span>(b)</span> b. were constructed as containerships, vehicle
          carriers or double-skin open-hatch gantry crane vessels (OHGCs) and
          have been continuously used as such on an established and regular
          pattern of trading between a range of specified ports, and do not
          exceed 30 years of age.
        </p>
      </div>
      <div>
        <h4 style="font-size: 18px">CRAFT CLAUSE</h4>
        <p>
          <span style="padding-right: 16px"><b>3</b></span
          >. The requirements of this clause do not apply to any craft used to
          load or unload the vessel within the port area.
        </p>
      </div>
      <div>
        <h4 style="font-size: 18px">NATIONAL FLAG SOCIETY</h4>
        <p>
          <span style="padding-right: 16px"><b>4</b></span
          >. A National Flag society is a Classification Society which is
          domiciled in the same country as the owner of the vessel in question
          which must also operate under the flag of that country.
        </p>
      </div>
      <div>
        <h4 style="font-size: 18px">LAW AND PRACTICE</h4>
        <p>
          <span style="padding-right: 16px"><b>4</b></span
          >. This insurance is subject to English law and practice.
        </p>
      </div>
      <div>
        <h3 style="font-size: 20px">CANCELLATION WARRANTY</h3>
        <p>
          It is hereby declared and agreed that the sum of N2,000.00 (single
          transit) would be charged if the cover granted by this
          Policy/Certificate is cancelled within (6) six months of issue and
          shall not be subject to cancellation after this period.
        </p>
      </div>
      <div>
        <h3 style="font-size: 20px">CLAIM NOTIFICATION CLAUSE</h3>
        <p>
          In the event of loss or damage to the insured interest which may
          involve a survey, immediate notice be made to Consolidated Hallmark
          Insurance Plc, who would arrange for survey and issue appropriate
          report on the claim.
        </p>
      </div>
      <div>
        <h3 style="font-size: 20px">NO PREMIUM NO COVER CLAUSE</h3>
        <p>
          The receipt of insurance premium shall be a condition precedent to a
          valid contract of insurance and there shall be no cover in respect of
          an insurance risk unless premium is paid in advance subject to the
          provisions of section 50 (1) of insurance Act 2003 Subject otherwise
          to the terms, conditions and exception of the policy.
        </p>
      </div>
      <div>
        <h3 style="font-size: 20px">CONVEYING VESSEL WARRANTY</h3>
        <p>
          Warranted that the name of the conveying Vessel/Steamer shall be
          advised by assured at least 30 Calendar days (30 days) before the
          arrival of the conveying Vessel/Steamer.
        </p>
      </div>
      <div>
        <h3 style="font-size: 20px">PORT DELAY CLAUSE</h3>
        <p>
          Notwithstanding the provisions of clause 1 of the Institute Cargo
          Clauses, this insurance shall terminate on the expiry of 60 days from
          midnight on the day of arrival of the overseas vessel at the final
          port of discharge of the goods hereby insured if their discharge has
          not by then been completed. Thereafter subject to prompt notice being
          given to underwriters the goods shall be held covered for a period and
          at a premium to be arranged; the period of up to 60 days specified
          above or any extension thereof shall nevertheless terminate
          immediately upon completion of discharge oversize from the overseas
          vessel and continuation of cover shall thereupon be governed by the
          provisions of clause 1 of the institute cargo clauses for any
          amendment thereto.
        </p>
        <p>
          Arrival shall be deemed to mean that the vessel is anchored moored or
          otherwise secured at a berth and / or place is not available, arrival
          is deemed to have occurred when the vessel first anchors, moors or
          otherwise secures either at or off the intended discharge port to
          await discharge berth.
        </p>
      </div>

      <div>
        <h3 style="font-size: 20px">INSTITUTE DANGEROUS DRUGS CLAUSE</h3>
        <p>
          It is understood and agreed that no claim under this policy will be
          paid in respect of drugs to which the various international
          conventions relating to Opium and other dangerous drugs unless-
        </p>
        <p>
          <span style="padding-right: 16px"><b>(1)</b></span
          >. the drugs shall be expressly declared as such in the policy and the
          name of the country from which, and the name of the country to which
          they are consigned shall be specifically stated in the policy
        </p>
        <p>
          <span style="padding-right: 16px"><b>(2)</b></span
          >. the proof of loss is accompanies either by a licence, certificate
          or authorization issued by the Government of the country to which the
          drugs are consigned showing that the importation of the consignment
          into the country has been approved by that Government, or, or
          alternatively by a licence, certificate or authorization issued by the
          Government of the country from which the drugs are consigned showing
          that the export of the consignment to the destination stated has been
          approved by that Government
        </p>
        <p>
          <span style="padding-right: 16px"><b>(3)</b></span
          >. the route by which the drugs were conveyed was usual and customary.
        </p>
        <p>
          <b>
            WARRANTED SUPERVISORY DISCHARGE OF CARGO BY CONSOLIDATED HALLMARK
            INSURANCE PLC APPOINTED SURVEYOR
          </b>
        </p>
      </div>

      <div>
        <h3 class="text-center my-5" style="font-size: 20px">IMPORTANT</h3>
        <h3 class="text-center my-3">
          PROCEDURE IN THE EVENT OF LOSS OR DAMAGE FOR WHICH UNDERWRITERS MAY BE
          LIABLE.
        </h3>
        <h3 style="text-decoration: underline; text-align: center">
          LIABILITY OF CARRIERS BAILEES OR OTHER THIRD PARTIES
        </h3>
        <p>
          It is the duty of the Assured and their Agents, in all cases to take
          such measure as may be reasonable for the purpose of averting or
          minimizing a loss and to ensure that all rights against Carriers
          Bailees or Other Third Parties are properly preserved and exercised.
          In particular, the Assured or their Agent are required:-
        </p>
        <p>
          <span style="padding-right: 16px"><b>(1)</b></span
          >. To claim immediately on the Carriers, Port Authorities or other
          Bailees for any missing packages.
        </p>
        <p>
          <span style="padding-right: 16px"><b>(2)</b></span
          >. In no circumstances except under written protest to give clean
          receipts where goods are in doubtful condition
        </p>
        <p>
          <span style="padding-right: 16px"><b>(3)</b></span
          >. When delivery is made by container, to ensure that the container
          and the seals are examined immediately by their responsible official.
          If the container is delivered damaged or with seals broken or missing
          or with seals other than as stated in the shipping documents, to
          clause the delivery receipt accordingly and retain all defective or
          irregular seals for subsequent identification.
        </p>
        <p>
          <span style="padding-right: 16px"><b>(4)</b></span
          >. To apply immediately for survey by Carriers’ or other Bailees
          Representatives if any loss or damage be apparent and claim on the
          Carriers or other Bailees for any actual loss or damage found at such
          Survey.
        </p>
        <p>
          <span style="padding-right: 16px"><b>(5)</b></span
          >. To give notice in writing to the Carriers or other Bailees within 3
          days of delivery if the loss or damage was not found apparent at the
          time of taking delivery.
        </p>
        <p>
          <b
            >NOTE: The consignees or their Agents are recommended to make
            themselves familiar with the regulations of the port Authorities at
            the port of discharge
          </b>
        </p>
      </div>

      <h3 style="text-decoration: underline; text-align: center">
        DOCUMENTATION OF CLAIMS
      </h3>
      <p>
        The enable claims to be dealt with promptly, the Assured or their Agents
        are advised to submit all available supporting documents without delay,
        including when applicable:-
      </p>
      <p>
        <span style="padding-right: 16px"><b>(1)</b></span
        >. Original policy or certificate of insurance.
      </p>
      <p>
        <span style="padding-right: 16px"><b>(2)</b></span
        >. Original or copy of shipping specification and /or weight notes.
      </p>
      <p>
        <span style="padding-right: 16px"><b>(3)</b></span
        >. Original Bill of lading and / or other contract of carriage.
      </p>
      <p>
        <span style="padding-right: 16px"><b>(4)</b></span
        >. Survey report or other documentary evidence to show the extent of the
        loss or damage.
      </p>
      <p>
        <span style="padding-right: 16px"><b>(5)</b></span
        >. Landing account and weight notes at final destination
      </p>
      <p>
        <span style="padding-right: 16px"><b>(6)</b></span
        >. Correspondence exchanged with the Carriers and other parties
        regarding their liability for the loss or damage.
      </p>

      <div v-if="singleBusiness.voyageType == 'Port To Warehouse'">
        <h3 style="font-size: 20px">CLEARANCE OF GOODS AMENDMENT WARRANTY</h3>

        <p>
          Notwithstanding the provisions of the Transit Clause (Clause 8) of the
          Institute Cargo Clauses and the clearance warranty stipulated in this
          policy, it is hereby warranted that the goods insured herein shall be
          cleared within 21 (twenty) days after discharge from the overseas
          carrying vessel.
        </p>

        <p>
          In the event that the insured consignment still remain un-cleared from
          the port within the is period due to delays arising outside the
          control of the insured and subject to prompt notice being given to the
          underwriters who will arrange for a Survey to determine the correct
          situation, the goods shall be held covered on the following
          conditions.
        </p>

        <p>
          <span style="padding-right: 16px"><b>(1)</b></span
          >. (sixty) days transit provision of the Institute Cargo Clauses to
          enable underwriters asses\s the situation and charge additional
          premium as appropriate for the extension.
        </p>

        <p>
          <span style="padding-right: 16px"><b>(2)</b></span
          >. (2) Underwriters, if satisfied with the reason for the delay shall
          charge an additional premium of 25% loading for the first 15 (fifteen)
          days after the expiration of the 21 (twenty) days provisions.
        </p>
      </div>

      <h3
        v-if="singleBusiness.voyageType == 'Port To Port'"
        style="font-size: 20px"
      >
        COVER TERMINATES IMMEDIATELY AFTER DISCHARGE OF CARGO FROM THE CARRYING
        VESSEL AT THE PORT
      </h3>
      <!-- 
      <table>
        <tr>
          <th>Clauses</th>
          <th>Rate</th>
          <th>MINIMUM PREMIUM</th>
        </tr>
        <tr>
          <td>Subject to Institute Cargo Clauses ‘A’</td>
          <td>0.1%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Cargo Clauses ‘B’</td>
          <td>0.075%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Cargo Clauses ‘C’</td>
          <td>0.05%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Cargo Clauses ‘Air’</td>
          <td>0.1%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Frozen Food Clauses ‘A’</td>
          <td>0.1%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Frozen Food Clauses ‘C’</td>
          <td>0.05%</td>
          <td>N5,000.00</td>
        </tr>
        <tr>
          <td>Subject to Institute Bulk Oil Clauses</td>
          <td>0.1%</td>
          <td>N5,000.00</td>
        </tr>
      </table> -->

      <p class="my-3" style="font-size: 14px; font-weight: bold">
        NOTE: - The Assured are required to give the earliest provisional notice
        of intended shipment advising in each case the name of the vessel and
        approximate value of the shipment
      </p>

      <section class="mt-4" v-if="singleBusiness.typeofCover == 'ICC\'A\''">
        <h3 class="my-3" style="text-align: center">
          1/1/09
          <span
            >INSTITUTE CARGO CLAUSES (A) {{ singleBusiness.typeofCover }}</span
          >
        </h3>
        <p><b>RISKS COVERED</b></p>
        <div>
          <span style="font-size: 14px; text-decoration: underline">Risks</span>
          <div style="display: flex">
            <p>1</p>
            <p style="padding-left: 50px">
              This insurance covers all risks of loss of damage to the
              subject-matter insured except as excluded by the provisions of
              clause 4, 5, 6 and 7 below.
            </p>
          </div>
        </div>
        <div>
          <span style="font-size: 14px; text-decoration: underline"
            >General Average</span
          >
          <div style="display: flex">
            <p>2</p>
            <p style="padding-left: 50px">
              This insurance covers general average and salvage charges,
              adjusted or determined according to the contract of carriage and /
              or the governing law and practice, incurred to avoid or in
              connection with the avoidance of loss from any cause except those
              in clauses 4, 5, 6 and 7 below.
            </p>
          </div>
        </div>
        <div>
          <span style="font-size: 14px; text-decoration: underline"
            >“Both to Blame Collision Clause”</span
          >
          <div style="display: flex">
            <p>3</p>
            <p style="padding-left: 50px">
              This insurance indemnifies the Assured, in respect of any risk
              insured herein, against liability incurred under any both to Blame
              Collision Clause in the contract of carriage. In the event of any
              claim by carriers under the said Clause, the Assured agree to
              notify the insurers who shall have the right, at their own cost
              and expense, to defend the Assured against such claim.
            </p>
          </div>
        </div>
        <div>
          <span style="font-size: 14px; text-decoration: underline"
            >EXCLUSIONS</span
          >
          <div style="display: flex">
            <p>4</p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.2</p>
            <p style="padding-left: 10px">
              ordinary leakage, ordinary loss in weight or volume, or ordinary
              wear and tear of the subject-matter insured.
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insufficiency or unsuitability of
              packing or preparation of the subject-matter insured to withstand
              the ordinary incidents of the insured transit where such packing
              or preparation is carried out by the Assured or their employees or
              prior to the attachment of this insurance (for the purpose of
              these Clauses “packing” shall be deemed to include stowage in a
              container and “employees” shall not include independent
              contractors)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by delay, even though the delay be
              caused by a risk insured against (except expenses payable under
              Clause 2 above)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insolvency or financial default
              of the owners managers charterers or operators of the vessel
              where, at the time of loading of the subject-matter insured on
              board the vessel, the Assured are aware, or in the ordinary course
              of business should be aware, that such insolvency or financial
              default could prevent the normal prosecution of the voyage This
              exclusion shall not apply where the contract of insurance has been
              assigned to the party claiming hereunder who has bought or agreed
              to buy the subject-matter insured in good faith under a binding
              contract
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.7</p>
            <p style="padding-left: 10px">
              loss damage or expense directly or indirectly caused or arising
              from the use of any weapon or device employing atomic or nuclear
              fission and/or fusion or other like reaction or radioactive force
              or matter.
            </p>
          </div>
        </div>
        <div>
          <div style="display: flex">
            <p>5</p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>5.1.1</p>
            <p style="padding-left: 10px">
              unseaworthiness of vessel or craft or unfitness of vessel or craft
              for the safe carriage of the subject-matter insured, where the
              Assured are privy to such unseaworthiness or unfitness, at the
              time the subject-matter insured is loaded therein
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>5.1.2</p>
            <p style="padding-left: 10px">
              unseaworthiness of vessel or craft or unfitness of vessel or craft
              for the safe carriage of the subject-matter insured, where the
              Assured are privy to such unseaworthiness or unfitness, at the
              time the subject-matter insured is loaded therein
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>5.2</p>
            <p style="padding-left: 10px">
              Exclusion 5.1.1 above shall not apply where the contract of
              insurance has been assigned to the party claiming hereunder who
              has bought or agreed to buy the subject-matter insured in good
              faith under a binding contract.
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>5.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
        </div>
        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>6.1</p>
          <p style="padding-left: 10px">
            war civil war revolution rebellion insurrection, or civil strife
            arising therefrom, or any hostile act by or against a belligerent
            power
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>6.2</p>
          <p style="padding-left: 10px">
            capture seizure arrest restraint or detainment (piracy excepted),
            and the consequences thereof or any attempt thereat
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>6.3</p>
          <p style="padding-left: 10px">
            derelict mines torpedoes bombs or other derelict weapons of war.
          </p>
        </div>
        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>7.1</p>
          <p style="padding-left: 10px">
            caused by strikers, locked-out workmen, or persons taking part in
            labour disturbances, riots or civil commotions
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>7.2</p>
          <p style="padding-left: 10px">
            resulting from strikes, lock-outs, labour disturbances, riots or
            civil commotions
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>7.3</p>
          <p style="padding-left: 10px">
            caused by any act of terrorism being an act of any person acting on
            behalf of, or in connection with, any organization which carries out
            activities directed towards the overthrowing or influencing, by
            force or violence, of any government whether or not legally
            constituted.
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>7.4</p>
          <p style="padding-left: 10px">
            caused by any person acting from a political, ideological or
            religious motive.
          </p>
        </div>
        <h3>DURATION</h3>
        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>
        <div style="padding-left: 20px">
          <p>8.1</p>
          <p style="padding-left: 10px">
            Subject to Clause 11 below, this insurance attaches from the time
            the subject-matter insured is first moved in the warehouse or at the
            place of storage (at the place named in the contract of insurance)
            for the purpose of the immediate loading into or onto the carrying
            vehicle or other conveyance for the commencement of transit,
          </p>
          <p style="padding-left: 10px">
            Continues during the ordinary course of transit
          </p>
          <p style="padding-left: 0px">And terminates either</p>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at the final warehouse or place of storage at the
              destination named in the contract of insurance,
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at any other warehouse or place of storage,
              whether prior to or at the destination named in the contract of
              insurance, which the Assured or their employees elect to use
              either for storage other than in the ordinary course of transit or
              for allocation or distribution, or
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.3</p>
            <p style="padding-left: 10px">
              when the Assured or their employees elect to use any carrying
              vehicle or other conveyance or any container for storage other
              than in the ordinary course of transit or
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.4</p>
            <p style="padding-left: 10px">
              on the expiry of 60 days after completion of discharge overside of
              the subject-matter insured from the oversea vessel at the final
              port of discharge, which shall first occur.
            </p>
          </div>
          <div style="display: flex; padding-left: -30px">
            <p>8.2</p>
            <p style="padding-left: 10px">
              If, after discharge overside from the oversea vessel at the final
              port of discharge, but prior to termination of this insurance, the
              subject-matter insured is to be forwarded to a destination other
              than that to which it is insured, this insurance, whilst remaining
              subject to termination as provided in clauses 8.1.1 to 8.1.4,
              shall not extend beyond the time the subject-matter insured is
              first moved for the purpose of the commencement of transit to such
              other destination.
            </p>
          </div>
          <h4 style="text-decoration: underline">
            Termination of Contract of Carriage
          </h4>
          <div style="display: flex; padding-left: -30px">
            <p>9</p>
            <p style="padding-left: 10px">
              if owing to circumstances beyond the control of the Assured either
              the contract of carriage is terminated at a port or place other
              than the destination named therein or the transit is otherwise
              terminated before unloading of the subject-matter insured as
              provided for in Clause 8 above, then this insurance shall also
              terminate unless prompt notice is given to the insurers and
              continuation of cover is requested when this insurance shall
              remain in force, subject to an additional premium if required by
              the insurer, either
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>9.1</p>
            <p style="padding-left: 10px">
              until the subject-matter insured is sold and delivered at such
              port or place, or unless otherwise specially agreed, until the
              expiry of 60 days after arrival of the subject-matter insured at
              such port or place, whichever shall first occur, Or
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>9.2</p>
            <p style="padding-left: 10px">
              if the subject-matter insured is forwarded within the said period
              of 60 days (or any agreed extension thereof) to the destination
              named in the contract of insurance or to any other destination,
              until terminated in accordance with the provision of Clause 8
              above.
            </p>
          </div>
          <h4 style="text-decoration: underline">Change of Voyage</h4>
          <div style="display: flex">
            <p>10</p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover loss damage or expense
            </p>
          </div>
          <div style="padding-left: 20px">
            <div style="display: flex">
              <p>10.1</p>
              <p style="padding-left: 10px">
                Where, after attachment of this insurance, the destination is
                changed by the Assured, this must be notified promptly to
                insurers for rates and terms to be agreed. Should a loss occur
                prior to such agreement being obtained cover may be provided but
                only if cover would have been available at a reasonable
                commercial market rate on reasonable market terms.
              </p>
            </div>
          </div>
          <div style="padding-left: 20px">
            <div style="display: flex">
              <p>10.2</p>
              <p style="padding-left: 10px">
                Where the subject-matter insured commences the transit
                contemplated by this insurance (in accordance with Clause 8.1),
                but, without the knowledge of the Assured or their employees the
                ship sails for another destination, his insurance will
                nevertheless be deemed to have attached at commencement of such
                transit.
              </p>
            </div>
          </div>

          <h3>CLAIMS</h3>

          <div style="display: flex">
            <p>11</p>
          </div>
          <div style="padding-left: 20px">
            <div style="display: flex">
              <p>11.1</p>
              <p style="padding-left: 10px">
                In order to recover under this insurance the Assured must have
                an insurable interest in the subject-matter insured at the time
                of the loss.
              </p>
            </div>
          </div>
          <div style="padding-left: 20px; display: flex">
            <p>11.2</p>
            <p style="padding-left: 10px">
              Subject to Clause 11.1 above, Assured shall be entitled to recover
              for insured loss occurring during the period covered by this
              insurance, notwithstanding that the loss occurred before the
              contract of insurance was concluded, unless the Assured were aware
              of the loss and the insurers were not.
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">Change of Voyage</h4>

        <div style="display: flex; flex-direction: column; padding-left: 20px">
          <div style="display: flex">
            <p>12</p>
            <p style="padding-left: 10px">
              where, as a result of the operation of a risk covered by this
              insurance, the insured transit is terminated at a port or place
              other than that to which the subject-matter insured is covered
              under this insurance, the insurers will reimburse the Assured for
              any extra charges properly and reasonably incurred in unloading
              storing and forwarding the subject-matter insured to the
              destination to which it is insured.
            </p>
          </div>

          <p style="padding-left: 10px">
            This Clause 12, which does not apply to general average or salvage
            charges, shall be subject to the exclusions contained in Clauses 4,
            5, 6, and 7 above, and shall not include charges arising from the
            fault negligence insolvency or financial default of the Assured or
            their employees.
          </p>
        </div>

        <h4 style="text-decoration: underline">Constructive Total Loss</h4>

        <div style="display: flex; flex-direction: column; padding-left: 20px">
          <div style="display: flex">
            <p>13</p>
            <p style="padding-left: 10px">
              No claim for Constructive Total Loss shall be recoverable
              hereunder unless the subject-matter insured is reasonably
              abandoned either on account of its actual total loss appearing to
              be unavoidable or because the cost of recovering, reconditioning
              and forwarding the subject-matter insured to the destination to
              which it is insured would exceed its value on arrival.
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">Increased Value</h4>

        <div style="display: flex">
          <p>14</p>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>14.1</p>
            <p style="padding-left: 10px">
              If any increased Value insurance is effected by the Assured on the
              subject-matter insured under this insurance the agreed value of
              the subject-matter insured shall be deemed to be increased to the
              total amount insured under this insurance and all increased Value
              insurances covering the loss, and liability under this insurance
              shall be in such proportion as the sum insured under this
              insurance bears to such total amount insured. In the event of
              claim the Assured shall provide the insurers with evidence of the
              amountinsured under all other insurances.
            </p>
          </div>
        </div>
        <div style="padding-left: 20px; display: flex">
          <p>14.2</p>
          <p style="padding-left: 10px">
            <b
              >Where this insurance is on Increased Value the following clause
              shall apply:</b
            >
            The agreed value of the subject-matter insured shall be deemed to be
            equal to the total amount insured under the primary insurance and
            all increased Value insurances covering the loss and effected on the
            subject-matter insured by the Assured, and liability under this
            insurance shall be in such proportion as the sum insured under this
            insurance bears to such total amount insured. { In the event of
            claim the Assured shall provide the insurers with evidence of the
            amounts insured under all other insurances.
          </p>
        </div>

        <h3>BENEFIT OF INSURANCE</h3>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 40px">This insurance</p>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>15.1</p>
            <p style="padding-left: 10px">
              cover the Assured which includes the person claiming indemnity
              either as the person by or on whose behalf the contract of
              insurance was effected or as an assignee,
            </p>
          </div>
        </div>
        <div style="padding-left: 20px; display: flex">
          <p>15.2</p>
          <p style="padding-left: 10px">
            shall not extend or otherwise benefit the carrier or other bailee.
          </p>
        </div>

        <h3>MINIMISING LOSS</h3>

        <p style="text-decoration: underline">Duty of Assured</p>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 40px">
            It is the duty of the Assured and their employees and agents in
            respect of loss recoverable hereunder
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>16.1</p>
            <p style="padding-left: 10px">
              to take such measures as may be reasonable for the purpose of
              averting or minimizing such loss, and
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>16.2</p>
            <p style="padding-left: 10px">
              to ensure that all rights against carriers, bailees or other third
              parties are properly preserved and exercised. And the insurers
              will, in addition to any loss recoverable hereunder, reimburse the
              Assured for any charges properly and reasonably incurred in
              pursuance of these duties.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 40px">
            Measures taken by the Assured or the insurers with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either control.
          </p>
        </div>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 40px">AVOIDANCE OF DELAY</p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 40px">
            This insurance is subject to English law and practice.
          </p>
        </div>

        <div style="display: flex">
          <p><b>NOTE:-</b></p>
          <p style="padding-left: 40px">
            Where a continuation of cover is requested under Clause 9, or a
            change of destination is notified under Clause 10, there is an
            obligation to give prompt notice to the insurers and the right to
            such cover is dependent upon compliance with this obligation.
          </p>
        </div>
      </section>

      <section v-if="singleBusiness.typeofCover == 'ICC\'B\''">
        <h3 style="text-align: center">
          1/1/09
          <span>INSTITUTE CARGO CLAUSES (B)</span>
        </h3>

        <p><b>RISKS COVERED</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">
            This insurance covers, except as excluded by the provisions of
            Clauses 4,5,6 and 7 below,
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.1</p>
            <p style="padding-left: 10px">
              loss of or damage to the subject-matter insured reasonably
              attributable to
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.1</p>
            <p style="padding-left: 10px">fire or explosion</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.2</p>
            <p style="padding-left: 10px">
              vessel or craft being stranded grounded sunk or capsized
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.3</p>
            <p style="padding-left: 10px">
              overturning or derailment of land conveyance
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.4</p>
            <p style="padding-left: 10px">
              collision or contact of vessel craft or conveyance with any
              external object other than water
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.5</p>
            <p style="padding-left: 10px">
              discharge of cargo at a port of distress
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.6</p>
            <p style="padding-left: 10px">
              1.1.1 earthquake volcanic eruption or lightning,
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.2</p>
            <p style="padding-left: 10px">
              loss or damage to the subject-matter insured caused by
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.1</p>
            <p style="padding-left: 10px">general average sacrifice</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.2</p>
            <p style="padding-left: 10px">jettison or washing overboard</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.3</p>
            <p style="padding-left: 10px">
              entry sea blake or river water into vessel craft hold conveyance
              container or place of storage,
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.3</p>
            <p style="padding-left: 10px">
              total loss of any package lost overboard or dropped whilst loading
              on to, or unloading from, vessel or craft
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">General Average</h4>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px">
            This insurance covers general average and salvage charges, adjusted
            or determined according to the contract of carriage and / or the
            governing law and practice, incurred to avoid or in connection with
            the avoidance of loss from any cause except those excluded in Clause
            4, 5, 6 and 7 below
          </p>
        </div>

        <h4 style="text-decoration: underline">
          “Both to Blame Collision Clause”
        </h4>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px">
            This insurance indemnifies the Assured, in respect of any risk
            insured herein, against liability incurred under any Both to Blame
            Collision Clause in the contract of carriage, In the event of any
            claim by carriers under the said Clause, the Assured agree to notify
            the Insurers who shall have the right, at their own cost and
            expense, to defend the Assured against such claim.
          </p>
        </div>

        <h4 style="text-decoration: underline">EXCLUSIONS</h4>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.2</p>
            <p style="padding-left: 10px">
              ordinary leakage, ordinary loss in weight or volume, or ordinary
              wear and tear of the subject-matter insured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insufficiency or unsuitability of
              packing or preparation of the subject-matter insured to withstand
              the ordinary incidents of the insured transit where such packing
              or preparation is carried out by the Assured or their employees or
              prior to the attachment of this insurance (for the purpose of
              these Clauses “packing” shall be deemed to include stowage in a
              container and “employees” shall not include independent
              contractors)
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by delay, even though the delay be
              caused by a risk insured against (except expenses payable under
              Clause 2 above)
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insolvency or financial default
              of the owners managers charterers or operators of the vessel
              where, at the time of loading of the subject-matter insured on
              board the vessel, the Assured are aware, or in the ordinary course
              of business should be aware, that such insolvency or financial
              default could prevent the normal prosecution of the voyage This
              exclusion shall not apply where the contract of insurance has been
              assigned to the party claiming hereunder who has bought or agreed
              to buy the subject-matter insured in good faith under a binding
              contract
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.7</p>
            <p style="padding-left: 10px">
              deliberate damage to or deliberate destruction of the
              subject-matter insured or any part thereof by the wrongful act of
              any person or persons
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.8</p>
            <p style="padding-left: 10px">
              4.8 loss damage or expense directly or indirectly caused by or
              arising from the use of any weapon or device employing atomic or
              nuclear fission and/or fusion or other like reaction or
              radioactive force or matter.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense arising
            from
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.1</p>
            <p style="padding-left: 10px">
              unseaworthiness of vessel or craft or unfitness of vessel or craft
              for the safe carriage of the subject-matter insured, where the
              Assured are privy to such unseaworthiness or unfitness, at the
              time the subject-matter insured is loaded therein
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>5.1.1</p>
            <p style="padding-left: 10px">
              unseaworthiness of vessel or craft or unfitness of vessel or craft
              for the safe carriage of the subject-matter insured, where the
              Assured are privy to such unseaworthiness or unfitness, at the
              time the subject-matter insured is loaded therein
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>5.1.2</p>
            <p style="padding-left: 10px">
              Unfitness of container or conveyance for the safe carriage of the
              subject-matter insured, where loading therein or thereon is
              carried our prior to attachment of this insurance or by the
              Assured or their employees and they are privy to such unfitness at
              the time of loading.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.2</p>
            <p style="padding-left: 10px">
              Exclusion 5.1.1 above shall not apply where the contract of
              insurance has been assigned to the party claiming hereunder who
              has bought or agreed to buy the subject-matter insured in good
              faith under a binding contract.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.3</p>
            <p style="padding-left: 10px">
              The insurers waive any breach of the implied warranties of
              seaworthiness of the ship and fitness of the ship to carry the
              subject-matter insured to destination.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.1</p>
            <p style="padding-left: 10px">
              war civil war revolution rebellion insurrection, or civil strife
              arising therefrom, or any hostile act by or war against a
              belligerent power
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.2</p>
            <p style="padding-left: 10px">
              capture seizure arrest restraint or detainment, and the
              consequences thereof or any attempt thereat
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.3</p>
            <p style="padding-left: 10px">
              derelict mines torpedoes bombs or other derelict weapons of war.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.1</p>
            <p style="padding-left: 10px">
              caused by strikers, locked-out workmen, or persons taking part in
              labour disturbances, riots or civil commotions
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.2</p>
            <p style="padding-left: 10px">
              resulting from strikes, lock-outs, labour disturbances, riots or
              civil commotion
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.3</p>
            <p style="padding-left: 10px">
              caused by any act of terrorism being an act of any person acting
              on behalf of, or in connection with, any organization which
              carries out activities directed towards the overthrowing or
              influencing, by force or violence, of any government whether or
              not legally constituted
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.4</p>
            <p style="padding-left: 10px">
              caused by any person acting from a political, ideological or
              religious motive.
            </p>
          </div>
        </div>

        <h3><b>DURATION</b></h3>

        <h4 style="text-decoration: underline">Transit Clause</h4>

        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.1</p>
            </div>

            <div>
              <p style="padding-left: 10px">
                Subject to Clause 11 below, this insurance attaches from the
                time the subject –matter insured is first moved in the warehouse
                or at the place of storage (at the place named in the contract
                of insurance) for the purpose of the immediate loading into or
                onto the carrying vehicle or other conveyance for the
                commencement of transit,
              </p>

              <p>Continues during the ordinary course of transit</p>

              <p>And terminates either</p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at the final warehouse or place of storage at the
              destination named in the contract of insurance,
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at any other warehouse or place of storage,
              whether prior to or at the destination named in the contract of
              insurance, which the Assured or their employees elect to use
              either for storage other than in the ordinary course of transit or
              their allocation or distribution, or
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.3</p>
            <p style="padding-left: 10px">
              when the Assured or their employees elect to use any carrying
              vehicle or other conveyance or any container for storage other
              than in the ordinary course of transit or
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.4</p>
            <p style="padding-left: 10px">
              on the expiry of 60 days after completion of discharge overside of
              the subject-matter insured from the oversea vessel at the final
              port of discharge, whichever shall first occur
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.2</p>
            <p style="padding-left: 10px">
              if, after discharge overside from the oversea vessel at the final
              port of discharge, but prior to termination of this insurance, the
              subject-matter insured is to be forwarded to a destination other
              than that to which it is insured, this insurance, whilst remaining
              subject to termination as provided in Clauses 8.1.1 to 8.1.4,
              shall not extend beyond the time the subject-matter insured is
              first moved for the purpose of the commencement of transit to such
              other destination.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.3</p>
            <p style="padding-left: 10px">
              This insurance shall remain in force (subject to termination as
              provided for in Clauses 8.1.1 to 8.1.4 above and to the provisions
              of Clause 9 below) during delay beyond the control of the Assured,
              any deviation, forced discharge, reshipment and during any
              variation of the adventure arising from the exercise of a liberty
              granted to carriers under the contract of carriage
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">
          Termination of Contract of Carriage
        </h4>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 40px">
            If owing to circumstances beyond the control of the Assured either
            the contract of carriage is terminated at a port or place other than
            the destination named therein or the transit is otherwise terminated
            before unloading of the subject-matter insured as provided for in
            Clause 8 above, then this insurance shall also terminate unless
            prompt notice is given to the insurers and continuation of cover is
            requested when this insurance shall remain in force, subject to an
            additional premium if required by the insurers, either
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>9.1</p>
            <p style="padding-left: 10px">
              until the subject-matter insured is sold and delivered at such
              port or place, or, unless otherwise specially agreed, until the
              expiry of 60 days after arrival of the subject-matter insured at
              such port or place, whichever shall first occur, <br />
              Or
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>9.2</p>
            <p style="padding-left: 10px">
              if the subject-matter insured is forwarded within the said period
              of 60 days (or any agreed extension thereof) to the destination
              named in the contract of insurance or to any other destination,
              until termination in accordance with the provision of Clause 8
              above.
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">Change of Voyage</h4>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>10.1</p>
            <p style="padding-left: 10px">
              Where, after attachment of this insurance, the destination is
              changed by the Assured, this must be notified promptly to insurers
              for rates and terms to be agreed. Should a loss occur prior to
              such agreement being obtained cover may be provided but only if
              cover would have been available at a reasonable commercial market
              rate on reasonable market terms.
            </p>
          </div>
          <div style="display: flex">
            <p>10.2</p>
            <p style="padding-left: 10px">
              Where the subject-matter insured commences the transit
              contemplated by this insurance (in accordance with Clause 8.1),
              but, without the knowledge of the Assured or their employees the
              ship sails for another destination, his insurance will
              nevertheless be deemed to have attached at commencement of such
              transit
            </p>
          </div>
        </div>

        <h3>CLAIMS</h3>

        <h4 style="text-decoration: underline">Insurable Interest</h4>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="display: flex">
          <p>11.1</p>
          <p style="padding-left: 10px">
            In order to recover under this insurance the Assured must have an
            insurable interest in the subject-matter insured at the time of the
            loss.
          </p>
        </div>

        <div style="display: flex">
          <p>11.2</p>
          <p style="padding-left: 10px">
            Subject to Clause 11.1 above, Assured shall be entitled to recover
            for insured loss occurring during the period covered by this
            insurance, notwithstanding that the loss occurred before the
            contract of insurance was concluded, unless the Assured were aware
            of the loss and the insurers were not.
          </p>
        </div>

        <h4 style="text-decoration: underline">Forwarding Charges</h4>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 40px">
            Where, as a result of the operation of a risk covered by this
            insurance, the insured transit is terminated at a port or place
            other than that to which the subject-matter insured is covered under
            this insurance, the insurers will reimburse the Assured for any
            extra charges properly and reasonably incurred in unloading storing
            and forwarding the subject-matter insured to the destination to
            which it is insured. <br />

            This Clause 12, which does not apply to general average or salvage
            charges, shall be subject to the exclusions contained in Clauses 4,
            5, 6, and 7 above, and shall not include charges arising from the
            fault negligence insolvency or financial default of the Assured or
            their employees.
          </p>
        </div>

        <h4 style="text-decoration: underline">Constructive Total Loss</h4>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 40px">
            No claim for Constructive Total Loss shall be recoverable hereunder
            unless the subject-matter insured is reasonably abandoned either on
            account of its actual total loss appearing to be unavoidable or
            because the cost of recovering, reconditioning and forwarding the
            subject-matter insured to the destination to which it is insured
            would exceed its value on arrival.
          </p>
        </div>

        <h4 style="text-decoration: underline">Increased Value</h4>

        <div style="display: flex">
          <p>14</p>
        </div>

        <div style="display: flex">
          <p>14.1</p>
          <p style="padding-left: 10px">
            If any increased Value insurance is effected by the Assured on the
            subject-matter insured under this insurance the agreed value of the
            subject-matter insured shall be deemed to be increased to the total
            amount insured under this insurance and all increased Value
            insurances covering the loss, and liability under this insurance
            shall be in such proportion as the sum insured under this insurance
            bears to such total amount insured. In the event of claim the
            Assured shall provide the insurers with evidence of the amount
            insured under all other insurances.
          </p>
        </div>

        <div style="display: flex">
          <p>14.2</p>
          <p style="padding-left: 10px">
            <b
              >Where this insurance is on Increased Value the following clause
              shall apply:</b
            >
            The agreed value of the subject-matter insured shall be deemed to be
            equal to the total amount insured under the primary insurance and
            all increased Value insurances covering the loss and effected on the
            subject-matter insured by the Assured, and liability under this
            insurance shall be in such proportion as the sum insured under this
            insurance bears to such total amount insured.
          </p>
        </div>

        <h4 style="text-decoration: underline">BENEFIT OF INSURANCE</h4>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 10px">This Insurance</p>
        </div>

        <div style="display: flex">
          <p>15.1</p>
          <p style="padding-left: 10px">
            cover the Assured which includes the person claiming indemnity
            either as the person by or on whose behalf the contract of insurance
            was effected or as an assignee,
          </p>
        </div>

        <div style="display: flex">
          <p>15.2</p>
          <p style="padding-left: 10px">
            shall not extend or otherwise benefit the carrier or other bailee.
          </p>
        </div>

        <h3>MINIMISING LOSSES</h3>

        <h4 style="text-decoration: underline">Duty of Assured</h4>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 10px">
            It is the duty of the Assured and their employees and agents in
            respect of loss recoverable hereunder
          </p>
        </div>

        <div style="display: flex">
          <p>16.1</p>
          <p style="padding-left: 10px">
            to take such measures as may be reasonable for the purpose of
            averting or minimizing such loss, and
          </p>
        </div>

        <div style="display: flex">
          <p>16.2</p>
          <p style="padding-left: 10px">
            to ensure that all rights against carriers, bailees or other third
            parties are properly preserved and exercised. And the insurers will,
            in addition to any loss recoverable hereunder, reimburse the Assured
            for any charges properly and reasonably incurred in pursuance of
            these duties.
          </p>
        </div>

        <h4 style="text-decoration: underline">Waiver</h4>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 10px">
            Measures taken by the Assured or the insurers with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either control./p>
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 10px">
            It is a condition of this insurance that the Assured shall act with
            reasonable dispatch in all circumstances within their control.
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 10px">
            This insurance is subject to English law and practice.
          </p>
        </div>

        <p>
          <b>NOTE:- </b>Where a continuation of cover is requested under Clause
          9, or a change of destination is notified under Clause 10, there is an
          obligation to give prompt notice to the insurers and the right to such
          cover is dependent upon compliance with this obligation.
        </p>
      </section>

      <section v-if="singleBusiness.typeofCover == 'ICC\'C\''">
        <h3 style="text-align: center">
          1/1/09
          <span>INSTITUTE CARGO CLAUSES (C)</span>
        </h3>

        <p><b>RISKS COVERED</b></p>

        <h4 style="text-decoration: underline">Risks</h4>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">
            This insurance covers, except as excluded by the provisions of
            Clauses 4,5,6 and 7 below,
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.1</p>
            <p style="padding-left: 10px">
              1.1 loss of or damage to the subject-matter insured reasonably
              attributable to
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.1</p>
            <p style="padding-left: 10px">fire or explosion</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.2</p>
            <p style="padding-left: 10px">
              vessel or craft being stranded grounded sunk or capsized
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.3</p>
            <p style="padding-left: 10px">
              overturning or derailment of land conveyance
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.1.4</p>
            <p style="padding-left: 10px">
              collision or contact of vessel craft or conveyance with any
              external object other than water
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.2</p>
            <p style="padding-left: 10px">
              loss or damage to the subject-matter insured caused by
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.1</p>
            <p style="padding-left: 10px">general average sacrifice</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.2</p>
            <p style="padding-left: 10px">jettison</p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.3</p>
            <p style="padding-left: 10px">
              entry sea blake or river water into vessel craft hold conveyance
              container or place of storage,
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>1.3</p>
            <p style="padding-left: 10px">
              total loss of any package lost overboard or dropped whilst loading
              on to, or unloading from, vessel or craft
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">General Average</h4>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 10px">
            This insurance covers general average and salvage charges, adjusted
            or determined according to the contract of carriage and / or the
            governing law and practice, incurred to avoid or in connection with
            the avoidance of loss from any cause except those excluded in Clause
            4, 5, 6 and 7 below.
          </p>
        </div>

        <h4 style="text-decoration: underline">
          “Both to Blame Collision Clause”
        </h4>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 10px">
            This insurance indemnifies the Assured, in respect of any risk
            insured herein, against liability incurred under any Both to Blame
            Collision Clause in the contract of carriage, In the event of any
            claim by carriers under the said Clause, the Assured agree to notify
            the Insurers who shall have the right, at their own cost and
            expense, to defend the Assured against such claim.
          </p>
        </div>

        <h4 style="text-decoration: underline">EXCLUSIONS</h4>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.2</p>
            <p style="padding-left: 10px">
              ordinary leakage, ordinary loss in weight or volume, or ordinary
              wear and tear of the subject-matter insured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insufficiency or unsuitability of
              packing or preparation of the subject-matter insured to withstand
              the ordinary incidents of the insured transit where such packing
              or preparation is carried out by the Assured or their employees or
              prior to the attachment of this insurance (for the purpose of
              these Clauses “packing” shall be deemed to include stowage in a
              container and “employees” shall not include independent
              contractors)
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by delay, even though the delay be
              caused by a risk insured against (except expenses payable under
              Clause 2 above)
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insolvency or financial default
              of the owners managers charterers or operators of the vessel
              where, at the time of loading of the subject-matter insured on
              board the vessel, the Assured are aware, or in the ordinary course
              of business should be aware, that such insolvency or financial
              default could prevent the normal prosecution of the voyage This
              exclusion shall not apply where the contract of insurance has been
              assigned to the party claiming hereunder who has bought or agreed
              to buy the subject-matter insured in good faith under a binding
              contract
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.7</p>
            <p style="padding-left: 10px">
              deliberate damage to or deliberate destruction of the
              subject-matter insured or any part thereof by the wrongful act of
              any person or persons
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>4.8</p>
            loss damage or expense directly or indirectly caused by or arising
            from the use of any weapon or device employing atomic or nuclear
            fission and/or fusion or other like reaction or radioactive force or
            matter.
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.1</p>
            <p style="padding-left: 10px">
              5.5.1 In no case shall this insurance cover loss damage or expense
              arising from
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>5.1.1</p>
            <p style="padding-left: 10px">
              unseaworthiness of vessel or craft or unfitness of vessel or craft
              for the safe carriage of the subject-matter insured, where the
              Assured are privy to such unseaworthiness or unfitness, at the
              time the subject-matter insured is loaded therein
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>5.1.2</p>
            <p style="padding-left: 10px">
              Unfitness of container or conveyance for the safe carriage of the
              subject-matter insured, where loading therein or thereon is
              carried our prior to attachment of this insurance or by the
              Assured or their employees and they are privy to such unfitness at
              the time of loading.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.2</p>
            <p style="padding-left: 10px">
              Exclusion 5.1.1 above shall not apply where the contract of
              insurance has been assigned to the party claiming hereunder who
              has bought or agreed to buy the subject-matter insured in good
              faith under a binding contract.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>5.3</p>
            <p style="padding-left: 10px">
              The insurers waive any breach of the implied warranties of
              seaworthiness of the ship and fitness of the ship to carry the
              subject-matter insured to destination
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.1</p>
            <p style="padding-left: 10px">
              war civil war revolution rebellion insurrection, or civil strife
              arising therefrom, or any hostile act by or war against a
              belligerent power
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.2</p>
            <p style="padding-left: 10px">
              capture seizure arrest restraint or detainment, and the
              consequences thereof or any attempt thereat
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>6.3</p>
            <p style="padding-left: 10px">
              derelict mines torpedoes bombs or other derelict weapons of war.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.1</p>
            <p style="padding-left: 10px">
              caused by strikers, locked-out workmen, or persons taking part in
              labour disturbances, riots or civil commotions
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.2</p>
            <p style="padding-left: 10px">
              resulting from strikes, lock-outs, labour disturbances, riots or
              civil commotion
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.3</p>
            <p style="padding-left: 10px">
              caused by any act of terrorism being an act of any person acting
              on behalf of, or in connection with, any organization which
              carries out activities directed towards the overthrowing or
              influencing, by force or violence, of any government whether or
              not legally constituted
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>7.4</p>
            <p style="padding-left: 10px">
              caused by any person acting from a political, ideological or
              religious motive.
            </p>
          </div>
        </div>

        <h3><b>DURATION</b></h3>

        <h4 style="text-decoration: underline">Transit Clause</h4>

        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover loss damage or expense
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.1</p>
            </div>

            <div>
              <p style="padding-left: 10px">
                Subject to Clause 11 below, this insurance attaches from the
                time the subject –matter insured is first moved in the warehouse
                or at the place of storage (at the place named in the contract
                of insurance) for the purpose of the immediate loading into or
                onto the carrying vehicle or other conveyance for the
                commencement of transit,
              </p>

              <p>Continues during the ordinary course of transit</p>

              <p>And terminates either</p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at the final warehouse or place of storage at the
              destination named in the contract of insurance,
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on completion of unloading from the carrying vehicle or other
              conveyance in or at any other warehouse or place of storage,
              whether prior to or at the destination named in the contract of
              insurance, which the Assured or their employees elect to use
              either for storage other than in the ordinary course of transit or
              their allocation or distribution, or
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.3</p>
            <p style="padding-left: 10px">
              when the Assured or their employees elect to use any carrying
              vehicle or other conveyance or any container for storage other
              than in the ordinary course of transit or
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.4</p>
            <p style="padding-left: 10px">
              on the expiry of 60 days after completion of discharge overside of
              the subject-matter insured from the oversea vessel at the final
              port of discharge, whichever shall first occur
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.2</p>
            <p style="padding-left: 10px">
              if, after discharge overside from the oversea vessel at the final
              port of discharge, but prior to termination of this insurance, the
              subject-matter insured is to be forwarded to a destination other
              than that to which it is insured, this insurance, whilst remaining
              subject to termination as provided in Clauses 8.1.1 to 8.1.4,
              shall not extend beyond the time the subject-matter insured is
              first moved for the purpose of the commencement of transit to such
              other destination.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.3</p>
            <p style="padding-left: 10px">
              This insurance shall remain in force (subject to termination as
              provided for in Clauses 8.1.1 to 8.1.4 above and to the provisions
              of Clause 9 below) during delay beyond the control of the Assured,
              any deviation, forced discharge, reshipment and during any
              variation of the adventure arising from the exercise of a liberty
              granted to carriers under the contract of carriage
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">
          Termination of Contract of Carriage
        </h4>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 40px">
            If owing to circumstances beyond the control of the Assured either
            the contract of carriage is terminated at a port or place other than
            the destination named therein or the transit is otherwise terminated
            before unloading of the subject-matter insured as provided for in
            Clause 8 above, then this insurance shall also terminate unless
            prompt notice is given to the insurers and continuation of cover is
            requested when this insurance shall remain in force, subject to an
            additional premium if required by the insurers, either
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>9.1</p>
            <p style="padding-left: 10px">
              until the subject-matter insured is sold and delivered at such
              port or place, or, unless otherwise specially agreed, until the
              expiry of 60 days after arrival of the subject-matter insured at
              such port or place, whichever shall first occur, <br />
              Or
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>9.2</p>
            <p style="padding-left: 10px">
              if the subject-matter insured is forwarded within the said period
              of 60 days (or any agreed extension thereof) to the destination
              named in the contract of insurance or to any other destination,
              until termination in accordance with the provision of Clause 8
              above.
            </p>
          </div>
        </div>

        <h4 style="text-decoration: underline">Change of Voyage</h4>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>10.1</p>
            <p style="padding-left: 10px">
              Where, after attachment of this insurance, the destination is
              changed by the Assured, this must be notified promptly to insurers
              for rates and terms to be agreed. Should a loss occur prior to
              such agreement being obtained cover may be provided but only if
              cover would have been available at a reasonable commercial market
              rate on reasonable market terms.
            </p>
          </div>
          <div style="display: flex">
            <p>10.2</p>
            <p style="padding-left: 10px">
              Where the subject-matter insured commences the transit
              contemplated by this insurance (in accordance with Clause 8.1),
              but, without the knowledge of the Assured or their employees the
              ship sails for another destination, his insurance will
              nevertheless be deemed to have attached at commencement of such
              transit
            </p>
          </div>
        </div>

        <h3>CLAIMS</h3>

        <h4 style="text-decoration: underline">Insurable Interest</h4>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="display: flex">
          <p>11.1</p>
          <p style="padding-left: 10px">
            In order to recover under this insurance the Assured must have an
            insurable interest in the subject-matter insured at the time of the
            loss.
          </p>
        </div>

        <div style="display: flex">
          <p>11.2</p>
          <p style="padding-left: 10px">
            Subject to Clause 11.1 above, Assured shall be entitled to recover
            for insured loss occurring during the period covered by this
            insurance, notwithstanding that the loss occurred before the
            contract of insurance was concluded, unless the Assured were aware
            of the loss and the insurers were not.
          </p>
        </div>

        <h4 style="text-decoration: underline">Forwarding Charges</h4>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 40px">
            Where, as a result of the operation of a risk covered by this
            insurance, the insured transit is terminated at a port or place
            other than that to which the subject-matter insured is covered under
            this insurance, the insurers will reimburse the Assured for any
            extra charges properly and reasonably incurred in unloading storing
            and forwarding the subject-matter insured to the destination to
            which it is insured. <br />

            This Clause 12, which does not apply to general average or salvage
            charges, shall be subject to the exclusions contained in Clauses 4,
            5, 6, and 7 above, and shall not include charges arising from the
            fault negligence insolvency or financial default of the Assured or
            their employees.
          </p>
        </div>

        <h4 style="text-decoration: underline">Constructive Total Loss</h4>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 40px">
            No claim for Constructive Total Loss shall be recoverable hereunder
            unless the subject-matter insured is reasonably abandoned either on
            account of its actual total loss appearing to be unavoidable or
            because the cost of recovering, reconditioning and forwarding the
            subject-matter insured to the destination to which it is insured
            would exceed its value on arrival.
          </p>
        </div>

        <h4 style="text-decoration: underline">Increased Value</h4>

        <div style="display: flex">
          <p>14</p>
        </div>

        <div style="display: flex">
          <p>14.1</p>
          <p style="padding-left: 10px">
            If any increased Value insurance is effected by the Assured on the
            subject-matter insured under this insurance the agreed value of the
            subject-matter insured shall be deemed to be increased to the total
            amount insured under this insurance and all increased Value
            insurances covering the loss, and liability under this insurance
            shall be in such proportion as the sum insured under this insurance
            bears to such total amount insured. In the event of claim the
            Assured shall provide the insurers with evidence of the amount
            insured under all other insurances.
          </p>
        </div>

        <div style="display: flex">
          <p>14.2</p>
          <p style="padding-left: 10px">
            <b
              >Where this insurance is on Increased Value the following clause
              shall apply:</b
            >
            The agreed value of the subject-matter insured shall be deemed to be
            equal to the total amount insured under the primary insurance and
            all increased Value insurances covering the loss and effected on the
            subject-matter insured by the Assured, and liability under this
            insurance shall be in such proportion as the sum insured under this
            insurance bears to such total amount insured.
          </p>
        </div>

        <h4 style="text-decoration: underline">BENEFIT OF INSURANCE</h4>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 10px">This Insurance</p>
        </div>

        <div style="display: flex">
          <p>15.1</p>
          <p style="padding-left: 10px">
            cover the Assured which includes the person claiming indemnity
            either as the person by or on whose behalf the contract of insurance
            was effected or as an assignee,
          </p>
        </div>

        <div style="display: flex">
          <p>15.2</p>
          <p style="padding-left: 10px">
            shall not extend or otherwise benefit the carrier or other bailee.
          </p>
        </div>

        <h3>MINIMISING LOSSES</h3>

        <h4 style="text-decoration: underline">Duty of Assured</h4>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 10px">
            It is the duty of the Assured and their employees and agents in
            respect of loss recoverable hereunder
          </p>
        </div>

        <div style="display: flex">
          <p>16.1</p>
          <p style="padding-left: 10px">
            to take such measures as may be reasonable for the purpose of
            averting or minimizing such loss, and
          </p>
        </div>

        <div style="display: flex">
          <p>16.2</p>
          <p style="padding-left: 10px">
            to ensure that all rights against carriers, bailees or other third
            parties are properly preserved and exercised. And the insurers will,
            in addition to any loss recoverable hereunder, reimburse the Assured
            for any charges properly and reasonably incurred in pursuance of
            these duties.
          </p>
        </div>

        <h4 style="text-decoration: underline">Waiver</h4>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 10px">
            Measures taken by the Assured or the insurers with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either control./p>
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 10px">
            It is a condition of this insurance that the Assured shall act with
            reasonable dispatch in all circumstances within their control.
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 10px">
            This insurance is subject to English law and practice.
          </p>
        </div>

        <p>
          <b>NOTE:- </b>Where a continuation of cover is requested under Clause
          9, or a change of destination is notified under Clause 10, there is an
          obligation to give prompt notice to the insurers and the right to such
          cover is dependent upon compliance with this obligation.
        </p>
      </section>

      <section v-if="singleBusiness.typeofCover == 'ICC(Air)'">
        <h3 style="text-align: center">
          <span>1/1/82 </span>INSTITUTE CARGO CLAUSE (AIR)<br />(Excluding
          Sendings By Post)
        </h3>

        <h3><b>RISKS COVERED</b></h3>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">Risks Clause</p>
        </div>

        <p style="padding-left: 40px">
          This insurance covers all risks of loss of or damage to the
          subject-matter insured except as provided in Clauses 2, 3 and 4 below.
        </p>

        <h3><b>EXCLUSIONS</b></h3>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px"><b>General Exclusions Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense attributable to willful misconduct of the
                Assured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                ordinary leakage, ordinary loss in weight or volume, or ordinary
                wear and tear of the subject-matter insured
              </p>
            </div>
          </div>

          <div style="display: flex">
            <div>
              <p>2.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insufficiency or unsuitability
                of packing or preparation of the subject-matter insured (for the
                purpose of this Clause 2.3 “packing” shall be deemed to include
                stowage in a container or liftvan but only when such stowage is
                carried out prior to attachment of this insurance or by the
                Assured or their servants)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.4</p>
            </div>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.5</p>
            </div>
            <p style="padding-left: 10px">
              loss damage or expense arising from unfitness of aircraft
              conveyance container or liftvan for the safe carriage of the
              subject-matter insured, where the Assured or their servants are
              privy to such unfitness at the time the subject-matter insured is
              loaded therein
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.6</p>
            </div>
            <p style="padding-left: 10px">
              2.6 loss damage or expense proximately caused by delay even though
              the delay be caused by a risk insured against
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.7</p>
            </div>
            <p style="padding-left: 10px">
              loss damage or expense arising from insolvency or financial
              default of the owners managers charterers or operators of the
              aircraft
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.8</p>
            </div>
            <p style="padding-left: 10px">
              loss damage or expense arising from the use of any weapon of war
              employing atomic or nuclear fission and / or fusion or other like
              reaction or radioactive force or matter.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px"><b>War Exclusion Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover loss damage or expense caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                war civil war revolution rebellion insurrection, or civil strife
                arising therefrom, or any hostile act by or against a
                belligerent power
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                capture seizure arrest restraint or detainment (piracy
                excepted), and the consequences thereof or any attempt thereat
              </p>
            </div>
          </div>

          <div style="display: flex">
            <div>
              <p>3.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                derelict mines torpedoes bombs or other derelict weapons of war.
              </p>
            </div>
          </div>

          <div style="display: flex">
            <p>4</p>
            <p style="padding-left: 40px"><b>Strikes Exclusion Clause</b></p>
          </div>

          <div style="display: flex">
            <p></p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover loss damage or expense
              caused by
            </p>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>4.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  caused by strikers, lock-outs, labour disturbances, riots or
                  civil commotions
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>4.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  resulting from strikes, lock-outs, labour disturbances, riots
                  or civil commotions
                </p>
              </div>
            </div>

            <div style="display: flex">
              <div>
                <p>4.3</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  caused by any terrorist or any person acting from a political
                  motive.
                </p>
              </div>
            </div>
          </div>

          <div style="display: flex">
            <p>5</p>
            <p style="padding-left: 40px"><b>Transit Clause</b></p>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>5.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  This insurance attaches from the time the subject-matter
                  leaves the warehouse, premises or place of stage at the place
                  named herein for the commencement of the transit, continues
                  during the ordinary course of transit and terminates either
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 40px">
            <div style="display: flex">
              <p>5.1.1</p>
              <p style="padding-left: 10px">
                on delivery to the Consignees’ or other final warehouse,
                premises or place of storage at the destination named herein,
              </p>
            </div>
          </div>

          <div style="padding-left: 40px">
            <div style="display: flex">
              <p>5.1.2</p>
              <p style="padding-left: 10px">
                on delivery to any other warehouse, premises or place of
                storage, whether prior to or at the destination named herein,
                which the Assured elect to use either
              </p>
            </div>
          </div>

          <div style="padding-left: 40px">
            <div style="display: flex">
              <p>5.1.2.1</p>
              <p style="padding-left: 10px">
                for storage other than the ordinary course of transit or
              </p>
            </div>
          </div>

          <div style="padding-left: 40px">
            <div style="display: flex">
              <p>5.1.2.2</p>
              <p style="padding-left: 10px">for allocation or distribution</p>
            </div>
          </div>

          <div style="padding-left: 40px">
            <div style="display: flex">
              <p>5.1.3</p>
              <p style="padding-left: 10px">
                on the expiry of 30 days after unloading the subject-matter
                insured from the aircraft at the final place of discharge,
                whichever shall first occur.
              </p>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>5.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  If, after unloading from the aircraft at the final place of
                  discharge, but prior to termination of this insurance, the
                  subject-matter insured is forwarded to a destination as
                  provided for above, shall not extend beyond the commencement
                  of transit to such other destination.
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>5.3</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  This insurance shall remain in force (subject to termination
                  as provided for above and to the provisions of Clause 6 below)
                  during delay beyond the control of the Assured, any deviation,
                  forced discharge, reshipment or transshipment and during any
                  variation of the adventure arising from the exercise of a
                  liberty granted to the air carriers under the control of
                  carriage.
                </p>
              </div>
            </div>
          </div>

          <div style="display: flex">
            <p>6</p>
            <p style="padding-left: 40px">
              <b>Termination of Contract of Carriage Clause </b>
            </p>
          </div>

          <p style="padding-left: 50px">
            If owing to circumstances beyond the control of the Assured either
            the contract of carriage is terminated at a place other than the
            destination named therein or the transit is otherwise terminated
            before delivery of the subject-matter insured as provided for in
            clause 5 above, then the insurance shall also terminate unless
            prompt notice is given to the Underwriters and continuation of cover
            is requested when the insurance shall remain in force, subject to an
            additional premium if required by the Underwriters, either
          </p>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>6.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  until the subject-matter is sold and delivered at such place
                  or, unless otherwise specially agreed until the expiry of the
                  30 days after arrival of the subject-matter hereby insured at
                  such place, whichever shall first occur.
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>6.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  If the subject-matter is forwarded within the said period of
                  30 days (or any agreed extension thereof) to the destination
                  named herein or to any other destination, until terminated in
                  accordance with the provisions of Clause 5 above.
                </p>
              </div>
            </div>
          </div>

          <div style="display: flex">
            <p>7</p>
            <p style="padding-left: 40px"><b>Change of Transit Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            Where, after attachment of this insurance, the destination changed
            by the Assured, held covered at a premium and on conditions to be
            arranged subject to prompt notice being given to the Underwriters.
          </p>

          <h3><b>CLAIMS</b></h3>

          <div style="display: flex">
            <p>8</p>
            <p style="padding-left: 40px"><b>Insurable Interest Clause</b></p>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>8.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  In order to recover under the insurance the Assured must have
                  an insurable interest in the subject-matter insured at the
                  time of the loss
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>8.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  Subject to 8.1 above, the Assured be entitled to cover for
                  insured loss occurring during the period covered by this
                  insurance, notwithstanding that the loss occurred before the
                  contract of insurance was concluded, unless the Assured were
                  aware of the loss and the Underwriters were not.
                </p>
              </div>
            </div>
          </div>

          <div style="display: flex">
            <p>9</p>
            <p style="padding-left: 40px"><b>Forwarding charges Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            Where, as a result of the operation of a risk covered by this
            insurance, the insured transit is terminated at a place other than
            that to which the subject- matter is covered under this insurance,
            the Underwriters will reimburse the Assured of any extra charges
            properly and reasonably incurred in unloading storing and forwarding
            the subject-matter to the destination to which it is insured
            hereunder. This Clause 9, which does not apply to general average or
            salvage charges, shall be subject to the exclusions contained in
            Clauses 2, 3 and 4 above, and shall not include charges arising from
            the fault negligence insolvency or financial default of the Assured
            or their servants.
          </p>

          <div style="display: flex">
            <p>10</p>
            <p style="padding-left: 40px">
              <b>Constructive Total Loss Clause</b>
            </p>
          </div>

          <p style="padding-left: 50px">
            No claim for Constructive Total Loss shall not be recoverable
            hereunder unless the subject-matter insured is reasonably abandoned
            either on account of its actual loss appearing to be unavoidable or
            because the cost of recovering, reconditioning and forwarding the
            subject-matter to the destination to which it is insured would
            exceed its value on arrival.
          </p>

          <div style="display: flex">
            <p>10</p>
            <p style="padding-left: 40px"><b>Increased Value Clause</b></p>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>10.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  If any Increased Value insurance is effected by the Assured on
                  thecargo insured herein the agreed value of the cargo shall be
                  deemed to be increased to the total amount insured under this
                  insurance and all proportion as the sum insured herein bears
                  to such total amount insured.
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>10.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  Where this insurance is on Increased Value the following
                  clause shall apply: The agreed value of the cargo shall be
                  deemed to be equal to the total amount insured under the
                  primary insurance and all Increased value insurance covering
                  the loss and effected on the cargo by the Assured,. and
                  liability under this insurance shall be in such proportion as
                  the sum insured herein bears to such total amount insured. In
                  the event of claim the Assured shall provide the Underwriters
                  with evidence of the amounts insured under all other
                  insurances
                </p>
              </div>
            </div>
          </div>

          <h3><b>BENEFIT OF INSURANCE</b></h3>

          <div style="display: flex">
            <p>11</p>
            <p style="padding-left: 40px"><b>Not to Inure Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            This insurance shall not inure to the benefit of the carrier or
            other bailee.
          </p>

          <h3><b>MINIMISING LOSSES</b></h3>

          <div style="display: flex">
            <p>12</p>
            <p style="padding-left: 40px"><b>Duty of Assured Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            It is the duty of the Assured and their servants and agents in
            respect of loss recoverable hereunder
          </p>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>12.1</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  to take such measures as may be reasonable for the purpose of
                  averting minimizing such loss, and
                </p>
              </div>
            </div>
          </div>

          <div style="padding-left: 20px">
            <div style="display: flex">
              <div>
                <p>12.2</p>
              </div>
              <div>
                <p style="padding-left: 10px">
                  to ensure that all rights against carriers, bailees or other
                  third parties are properly preserved and exercised and the
                  Underwriters will, in addition to any loss recoverable
                  hereunder, reimburse the Assured for any charges properly and
                  reasonably incurred in pursuance of these duties.
                </p>
              </div>
            </div>
          </div>

          <div style="display: flex">
            <p>13</p>
            <p style="padding-left: 40px"><b>Waiver Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            Measures taken by Assured or the Underwriters with the subject of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either party.
          </p>

          <h3><b>AVOIDANCE OF DELAY</b></h3>

          <div style="display: flex">
            <p>14</p>
            <p style="padding-left: 40px"><b>Reasonable Despatch Clause</b></p>
          </div>

          <p style="padding-left: 50px">
            It is a condition of this insurance that the Assured shall act with
            reasonable dispatch in all circumstances within their control.
          </p>

          <h3><b>LAW AND PRACICE</b></h3>
          <div style="display: flex">
            <p>15</p>
            <p style="padding-left: 40px">
              <b>English Law and Practice Clause</b>
            </p>
          </div>

          <p style="padding-left: 50px">
            This insurance is subject to English and practice.
          </p>

          <p>
            <b
              >NOTE: It is necessary for the Assured when they become aware of
              an event is held covered” under this insurance to give prompt
              notice to the Underwriters and the right to such cover is
              dependent upon compliance with this obligation.
            </b>
          </p>
        </div>
      </section>

      <section v-if="singleBusiness.typeofCover == 'IFFC\'A\''">
        <h3 style="text-align: center">
          1/1/09
          <span
            >INSTITUTE FROZEN FOOD CLAUSES (A)<br />
            (Excluding Frozen Meat)</span
          >
        </h3>

        <p><b>RISKS COVERED</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px"><b>Risks Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          This insurance covers, except as provided in Clauses 4,5,6 and 7
          below.
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                all risks of loss of or damage to the subject-matter insured,
                other than the loss or damage resulting from any variation in
                temperature however caused,
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss of or damage to the subject-matter insured resulting from
                any variation in temperature attributable
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.1</p>
            <p style="padding-left: 10px">
              breakdown of refrigerating machinery resulting in its stoppage for
              a period of not than 24 consecutive hours
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.2</p>
            <p style="padding-left: 10px">
              fire or craft being stranded grounded sunk or capsized
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.3</p>
            <p style="padding-left: 10px">
              overturning or derailment of land conveyance
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.4</p>
            <p style="padding-left: 10px">
              collision or contact of vessel craft or conveyance
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.5</p>
            <p style="padding-left: 10px">
              collision or contact of vessel craft or conveyance
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>1.2.6</p>
            <p style="padding-left: 10px">
              discharge of cargo at a port of distress
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px"><b>General Average Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          This insurance covers general average and salvage charges, adjusted or
          determined according to the contract of affreightment and/or the
          governing law and practice, incurred to avoid or in connection with
          the avoidance of loss from any cause except those excluded in Clauses
          4, 5, 6 and 7 or elsewhere in this insurance.
        </p>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px">
            <b>“Both to Blame Collision” Clause</b>
          </p>
        </div>

        <p style="padding-left: 50px">
          This insurance is extended to indemnify the Assured against such
          proportion of liability under the contract of affreightment “Both to
          Blame Collision” Clause as is in respect of a loss recoverable
          hereunder. In the event of any claim by shipowners under the said
          Clause the Assured agree to notify the Underwriters who shall have the
          right, at their own cost and expense, to defend the Assured against
          such claim.
        </p>

        <h3><b>EXCLUSIONS</b></h3>

        <div>
          <div style="display: flex">
            <p>4</p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.2</p>
            <p style="padding-left: 10px">
              ordinary leakage, ordinary loss in weight or volume, or ordinary
              wear and tear of the subject-matter insured.
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insufficiency or unsuitability of
              packing or preparation of the subject-matter insured (for the
              purpose of this Clause 4.3 “packing” shall be deemed to include
              stowage in a container or liftvan but only when such stowage is
              carried out prior to attachment of this insurance or by the
              Assured or their servants)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured (except loss damage or expense resulting
              from variation in temperature specifically covered under Clause
              1.2 above)p>
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured (except loss damage or expense resulting
              from variation in temperature specifically covered under Clause
              1.2 above)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from insolvency or financial
              default of the owners managers charterers or operators of the
              vessel
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.7</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from the use of any weapon of war
              employing atomic or nuclear fission and/or fusion or other like
              reaction or radioactive force or matter
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.8</p>
            <p style="padding-left: 10px">
              loss damage expense arising from any failure of the Assured or
              their servants to take all reasonable precautions to ensure that
              the subject-matter insured is kept in refrigerated or, where
              appropriate, properly insulated and cooled space
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.9</p>
            <p style="padding-left: 10px">
              any loss damage or expense otherwise recoverable hereunder unless
              prompt notice thereof is given to the Underwriters and, in any
              event, not later than 30 days after the termination of this
              insurance.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 40px">
            <b>Unseaworthiness and Unfitness Exclusion Clause</b>
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>5.1</p>
          <p style="padding-left: 10px">
            In no case shall this insurance cover loss damage or expense arising
            <br />
            from <br />
            unseaworthiness of vessel or craft, <br />
            unfitness of vessel craft conveyance container or unfitness, at the
            <br />
            time the subject-matter insured is loaded therein.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p></p>
          <p style="padding-left: 10px">
            Where the Assured or their servants are privy to such
            unseaworthiness or unfitness, at the time the subject-matter insured
            is loaded therein.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>5.2</p>
          <p style="padding-left: 10px">
            The Underwriters waive any breach of the implied warranties of
            seaworthiness of the ship and fitness of the ship to carry the
            subject- matter insured to destination, unless the Assured or their
            servants are privy to such unseaworthiness or unfitness.
          </p>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 40px"><b>War Exclusion Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 50px">
            In no case shall this insurance cover loss damage or expense caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                war civil war revolution rebellion insurrection, or civil strife
                arising therefrom, or any hostile act by or against a
                belligerent power
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                capture seizure arrest restraint or detainment (piracy
                excepted), and the consequences thereof or any attempt thereat
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                derelict mines torpedoes bombs or other derelict weapons of war.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 40px"><b>Strikes Exclusion Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          In no case shall this insurance cover loss damage or expense
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by strikers, locked-out workmen, or persons taking part
                in labour disturbances, riots or civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                resulting from strikes, lock-outs, labour disturbance, riots or
                civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by any terrorist or any person acting from a political
                motive..
              </p>
            </div>
          </div>
        </div>

        <h3>DURATION</h3>
        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 50px"><b>Transit Clause</b></p>
        </div>
        <div style="padding-left: 20px">
          <p>8.1</p>
          <p style="padding-left: 10px">
            This insurance attaches from the time the goods are loaded into the
            conveyance at freezing works or cold store at the place named herein
            for the commencement of the transit, continues during the ordinary
            course of transit and terminate either
          </p>
          <p style="padding-left: 10px">
            Continues during the ordinary course of transit
          </p>
          <p style="padding-left: 40px">And terminates either</p>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              on delivery to any cold store or place of storage at the
              destination named herein
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on delivery to any other cold store or place or storage, whether
              prior to or at the destination named herein, which the Assured
              elect to use either
            </p>
          </div>
          <div style="display: flex; padding-left: 40px">
            <p>8.1.2.1</p>
            <p style="padding-left: 10px">
              for storage other than in the ordinary course of transit or
            </p>
          </div>
          <div style="display: flex; padding-left: 40px">
            <p>8.1.2.2</p>
            <p style="padding-left: 10px">for allocation or distribution,</p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>8.1.3</p>
            <p style="padding-left: 10px">
              on the expiry of 5 days after discharge over side if the goods
              hereby insured from the oversea vessel at the final port of
              discharge whichever shall first occur
            </p>
          </div>

          <div style="display: flex">
            <div>
              <p>8.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If, after discharge overside from the oversea vessel at the
                final port of discharge, but prior to termination of this
                insurance, the goods are to be forwarded to a destination other
                than that to which they are insured hereunder, this insurance,
                whilst remaining subject to determination as provided for above,
                shall not extend beyond the commencement of transit to such
                other destination.
              </p>
            </div>
          </div>

          <div style="display: flex">
            <div>
              <p>8.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                This insurance shall remain in force (subject to termination as
                provided for above an to the provision of Clause 9 below) during
                delay beyond the control of the Assured, any deviation, force
                discharge, reshipment or transshipment and during any variation
                of the adventure arising from the exercise of a liberty granted
                to ship-owners or charterers under the contract of affreightment
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 40px">
            <b>Termination of Contract of Carriage Clause</b>
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>9.1</p>
          <p style="padding-left: 50px">
            If owing to circumstances beyond the control of the Assured either
            the contract of carriage is termination at a port or place other
            than the destination named therein or the transit is otherwise
            terminated before delivery of the goods as provided for in Clause 8
            above, then this insurance shall also terminate unless prompt notice
            is given to the Underwriters and continuation of cover is requested
            then the insurance shall remain in force, subject to an additional
            premium: if required by the Underwriters, either
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>9.2</p>
          <p style="padding-left: 50px">
            until the goods are sold and delivered at such port or place, or,
            unless otherwise specially agreed, until the expiry of 30days after
            arrival of the goods hereby insured at such port or place, whichever
            shall first occur, or
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>9.3</p>
          <p style="padding-left: 50px">
            if the goods are forwarded within the said period of 30days ( or any
            agreed extension thereof) to the destination named herein or to any
            other destination, until terminated in accordance with the
            provisions of Clause 8 above.
          </p>
        </div>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 50px"><b>Change of Voyage Clause</b></p>
        </div>

        <p style="padding-left: 70px">
          Where, after attachment of this insurance, the destination is changed
          by the Assured, held covered at a premium and on conditions to be
          arranged subject to prompt notice being to the Underwriters.
        </p>

        <h3>CLAIMS</h3>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 50px"><b>Insurable Interest Clause</b></p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.1</p>
          <p style="padding-left: 50px">
            In order to recover under this insurance the Assured must have an
            insurable interest in the subject-matter insured at the time of the
            loss.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.2</p>
          <p style="padding-left: 50px">
            Subject to 11.1 above, the Assured shall be entitled to recover for
            insured loss occurring during the period covered by this insurance,
            notwithstanding that the loss occurred before the contract of
            insurance was concluded, unless the Assured were aware of the loss
            and the Underwriters were not.
          </p>
        </div>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 50px"><b>Forwarding Charges Clause</b></p>
        </div>

        <p style="padding-left: 70px">
          Where, as a result of the operation of a risk covered by this
          insurance, the insured transit is terminated at a port or place other
          than that to which the subject-matter is covered under this insurance,
          the Underwriters will reimburse the Assured for any extra charges
          properly and reasonably incurred in unloading storing and forwarding
          the subject-matter to the destination to which it is insured
          hereunder..
        </p>

        <p style="padding-left: 70px">
          This Clause 12, which does not apply to general average or salvage
          charges, shall be subject to the exclusions contained in Clauses 4, 5,
          6, and 7 above, and shall not include charges arising from the fault
          negligence insolvency or financial default of the Assured or their
          servants.
        </p>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 50px">
            <b>Constructive Total loss Clause</b>
          </p>
        </div>

        <p style="padding-left: 70px">
          No claim for Constructive Total Loss shall be recoverable hereunder
          unless the subject-matter insured is reasonably abandoned either on
          account of its actual totalloss appearing to be avoidable or because
          the cost of recovering, reconditioning andforwarding the
          subject-matter to the destination to which it is insured would exceed
          its value on arrival.
        </p>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 50px"><b>Increased Value Clause</b></p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.1</p>
          <p style="padding-left: 40px">
            If any Increased Value insurance is effected by the Assured on the
            cargo insured herein the agreed value of the cargo shall be deemed
            to be increased to the total amount insured under this insurance and
            all Increased Value insurances covering the loss, and liability
            under this insurance shall be in such proportion as the sum insured
            herein bears to such total amount insured.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.2</p>
          <p style="padding-left: 40px">
            Where this insurance is on Increased Value the following clause
            shall apply: The agreed value of the cargo shall be deemed to be
            equal to the total amount insured under the primary insurance and
            all Increased Value insurances covering the loss and effected on the
            cargo by the Assured, and liability under this insurance shall be in
            such proportion as the sum insured herein bears to such total amount
            insured. In the event of claim the Assured shall provide the
            Underwriters with evidence of the amounts insured under all other
            insurances.
          </p>
        </div>

        <h3><b>BENEFIT OF INSURANCE</b></h3>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 40px"><b>Not to Inure Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          This insurance shall not inure to the benefit of the carrier or other
          bailee.
        </p>

        <h3><b>MINIMISING LOSSES</b></h3>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 40px"><b>Duty of Assured Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is the duty of the Assured and their servants and agents in respect
          of loss recoverable hereunder
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>16.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting minimizing such loss, and
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>16.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly preserved and exercised and the
                Underwriters will, in addition to any loss recoverable
                hereunder, reimburse the Assured for any charges properly and
                reasonably incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 40px"><b>Waiver Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          Measures taken by Assured or the Underwriters with the subject of
          saving, protecting or recovering the subject-matter insured shall not
          be considered as a waiver or acceptance of abandonment or otherwise
          prejudice the rights of either party.
        </p>

        <h3><b>AVOIDANCE OF DELAY</b></h3>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 40px"><b>Reasonable Despatch Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is a condition of this insurance that the Assured shall act with
          reasonable dispatch in all circumstances within their control.
        </p>

        <h3><b>LAW AND PRACICE</b></h3>
        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 40px">
            <b>English Law and Practice Clause</b>
          </p>
        </div>

        <p style="padding-left: 50px">
          This insurance is subject to English and practice.
        </p>

        <p>
          <b
            >NOTE: It is necessary for the Assured when they become aware of an
            event which is “held covered” under this insurance to give prompt
            notice to the Underwriters and the right to such cover is dependent
            upon compliance with this obligation.<br />
            SPECIAL NOTE: - This insurance does not cover loss damage or expense
            caused by embargo, or by rejection prohibition or detention by the
            government of the country of import or their agencies or
            departments, but does not exclude loss of or damage to the
            subject-matter insured caused by risks insured hereunder and
            sustained prior to any such embargo rejection prohibition or
            detention.
          </b>
        </p>
      </section>

      <section v-if="singleBusiness.typeofCover == 'IFFC\'C\''">
        <h3 style="text-align: center">
          1/1/09
          <span>INSTITUTE FROZEN FOOD CLAUSES C </span>
        </h3>

        <p><b>RISKS COVERED</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px"><b>Perils Clause</b></p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1</p>
          <p style="padding-left: 40px">
            1 This insurance covers, except as provided in Clauses 4, 5, 6 and 7
            below.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.1.1</p>
          <p style="padding-left: 40px">fire or explosion</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.1.2</p>
          <p style="padding-left: 40px">
            vessel or craft being stranded grounded sunk or capsized
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.1.3</p>
          <p style="padding-left: 40px">
            overturning or derailment of land conveyance
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.1.4</p>
          <p style="padding-left: 40px">
            collision or contact of vessel craft or conveyance with any external
            object other than water
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.1.5</p>
          <p style="padding-left: 40px">
            discharge of cargo at a port of distress
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.2</p>
          <p style="padding-left: 40px">
            loss of or damage to the subject-matter insured caused by
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.2.1</p>
          <p style="padding-left: 40px">general average sacrifice</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>1.2.2</p>
          <p style="padding-left: 40px">jettison</p>
        </div>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px"><b>General Average Clause</b></p>
        </div>

        <p style="padding-left: 60px">
          This insurance covers general average and salvage charges, adjusted or
          determined according to the contract of affreightment and /or the
          governing law and practice, incurred to avoid or in connection with
          the avoidance of loss from any cause except those excluded in Clause
          4, 5, and 7 or elsewhere in this insurance.
        </p>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px">
            <b>“Both to Blame Collision” Clause </b>
          </p>
        </div>

        <p style="padding-left: 60px">
          This insurance is extended to indemnify the Assured against such
          proportion of liability under the contract of affreightment “Both to
          Blame Collision” Clause as is in respect of a loss recoverable
          hereunder. In the event of any claim by ship-owners under the said
          Clause the Assured agreed to notify the Underwriters who shall have
          the right, at their own cost and expense, to defend the Assured
          against such claim.
        </p>

        <h3><b>EXCLUSIONS</b></h3>

        <div>
          <div style="display: flex">
            <p>4</p>
            <p style="padding-left: 50px">
              In no case shall this insurance cover
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.2</p>
            <p style="padding-left: 10px">
              ordinary leakage, ordinary loss in weight or volume, or ordinary
              wear and tear of the subject-matter insured.
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by insufficiency or unsuitability of
              packing or preparation of the subject-matter insured (for the
              purpose of this Clause 4.3 “packing” shall be deemed to include
              stowage in a container or liftvan but only when such stowage is
              carried out prior to attachment of this insurance or by the
              Assured or their servants)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense proximately caused by delay, even though
              the delay be caused by a risk insured against (except expenses
              payable under Clause 2 above)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from insolvency or financial
              default of the owners managers charterers or operator’s of the
              vessel
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.7</p>
            <p style="padding-left: 10px">
              deliberate damage to or deliberate destruction of the
              subject-matter insured or any part thereof by the wrongful act of
              any person or persons
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.8</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from the use of any weapon of war
              employing atomic or nuclear fission and /or fusion or other like
              reaction radioactive force or matter.
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.9</p>
            <p style="padding-left: 10px">
              Loss damage or expense arising from any failure of the Assured or
              their servants to take all reasonable precautions to ensure that
              the subject-matter insured is kept in refrigerated or where
              appropriate, properly insulted and cooled space
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.10</p>
            <p style="padding-left: 10px">
              Any loss damage or expense otherwise recoverable hereunder unless
              prompt notice thereof is given to the Underwriters and, in any
              event not later than 30days after the termination of this
              insurance.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>5.1</p>
          <p style="padding-left: 10px">
            In no case shall this insurance cover loss damage or expense arising
            from unseaworthiness of vessel or craft unfitness of vessel craft
            conveyance container or liftvan for the safe-carriage of the
            subject-matter insured, where the Assured or their servants are
            privy to such unseaworthiness or unfitness of the ship and fitness,
            at the time the subject- matter insured is loaded therein.
          </p>
        </div>
        <div style="display: flex; padding-left: 20px">
          <p>5.2</p>
          <p style="padding-left: 10px">
            The Underwriters waive any breach of the implied warranties of
            seaworthiness of the ship and fitness of the ship to carry the
            subject- matter insured to destination, unless the Assured or their
            servants are privy to such unseaworthiness or unfitness.
          </p>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 50px">War Risk Exclusion Clause</p>
        </div>

        <p style="padding-left: 60px">
          In no case shall this insurance cover loss damage or expense caused by
        </p>

        <div style="display: flex; padding-left: 20px">
          <p>6.1</p>
          <p style="padding-left: 10px">
            war civil war revolution rebellion insurrection, or civil strife
            arising therefrom, or any hostile act by or against a belligerent
            power
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>6.2</p>
          <p style="padding-left: 10px">
            capture seizure arrest restraint or detainment, and the consequences
            thereof or any attempt thereat
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>6.3</p>
          <p style="padding-left: 10px">
            derelict mines torpedoes bombs or other derelict weapons of war.
          </p>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 40px"><b>Strikes Exclusion Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by strikers, locked-out workmen, or persons taking part
                in labour disturbances, riots or civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                resulting from strikes, lock-outs, labour disturbance, riots or
                civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by any terrorist or any person acting from a political
                motive..
              </p>
            </div>
          </div>
        </div>

        <h3><b>DURATION</b></h3>

        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 50px">Transit Clause</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>8.1</p>
          <p style="padding-left: 10px">
            This insurance attaches from the time the goods are loaded into the
            conveyance at freezing works or cold store at the place named herein
            for the commencement of the transit, continues during the ordinary
            course of transit and terminates either
          </p>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              on delivery to the cold store or place of storage at the
              destination named herein, fire or explosion
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on delivery to any other cold store or place of storage, whether
              prior to or at the destination named herein, which the Assured
              elect to use either
            </p>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <p>8.1.2.1</p>
            <p style="padding-left: 10px">
              for storage other than in the ordinary course of transit or
            </p>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <p>8.1.2.2</p>
            <p style="padding-left: 10px">for allocation or distribution, or</p>
          </div>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>8.2</p>
          <p style="padding-left: 10px">
            If after discharge overside from the oversea vessel at the final
            port of discharge, but prior to termination of this insurance, the
            goods are to be forwarded to a destination other than that to which
            they are insured hereunder, this insurance, whilst remaining subject
            to termination as provided for above, shall not extend beyond the
            commencement of transit to such other destination.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>8.3</p>
          <p style="padding-left: 10px">
            This insurance shall remain in force (subject to termination as
            provided for above and to the provisions of Clause 9 below) during
            delay beyond the control of the Assured, any deviation, forced
            discharge, reshipment or transshipment and during any variation of
            the adventure arising from the exercise of a liberty granted to
            shipowners or charterers under the contract of affreightment.
          </p>
        </div>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 50px">
            Termination of Contract of Carriage Clause
          </p>
        </div>

        <p style="padding-left: 60px">
          If owing to circumstances beyond the control of the Assured either the
          contract of carriage is terminated at a port or place other than the
          destination named therein or the transit is otherwise terminated
          before delivery of the goods as provided for in Clause 8 above, then
          this insurance shall also terminate unless prompt notice is given to
          the Underwriters and continuation of cover is required then the
          insurance shall remain in force, subject to an additional premium if
          required by the Underwriters, either
        </p>

        <div style="display: flex; padding-left: 20px">
          <p>9.1</p>
          <p style="padding-left: 10px">
            until the goods are sold and delivered at such port or place, or,
            unless otherwise specially agreed, until the expiry of 30 days after
            arrival of the goods hereby insured at such port or place, whichever
            shall first occur, or
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>9.2</p>
          <p style="padding-left: 10px">
            if the goods are forwarded within the said period of 30 days (or any
            agreed extension thereof) to the destination named herein or to any
            other destination, until terminated in accordance with the
            provisions of Clause 8 above.
          </p>
        </div>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 50px">Change of Voyage Clause</p>
        </div>

        <p style="padding-left: 60px">
          Where, after the attachment of this insurance, the destination is
          changed by the Assured, held covered at a premium and on conditions to
          be arranged subject to prompt notice being given to the Underwriters.
        </p>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 50px">Insurable Interest Clause</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.1</p>
          <p style="padding-left: 10px">
            In order to recover under this insurance the Assured must have an
            insurable interest in the subject matter insured at the time of the
            loss.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.2</p>
          <p style="padding-left: 10px">
            Subject to 11.1 above, the Assured shall be entitled to recover for
            insured loss occurring during the period covered by this insurance,
            notwithstanding that the loss occurred before the contract of
            insurance was concluded, unless the Assured were aware of the loss
            and the Underwriters were not.
          </p>
        </div>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 50px">
            Forwarding Charges ClauseInsurable Interest Clause
          </p>
        </div>

        <p style="padding-left: 60px">
          Where, as a result of the operation of a risk covered by this
          insurance, the insured transit is terminated at a port or place other
          than that to which the subject matter is covered under this insurance,
          the Underwriters will reimburse the Assured for any extra charges
          properly and reasonably incurred in unloading storing and forwarding
          the subject matter to the destination to which it is insured
          hereunder.
        </p>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 50px">Constructive Total Loss Clause</p>
        </div>

        <p style="padding-left: 60px">
          No claim for Constructive Total Loss shall be recoverable hereunder
          unless the subject matter is reasonably abandoned either on account of
          its actual total loss appearing to be unavoidable or because the cost
          of recovering, reconditioning and forwarding the subject matter to the
          destination to which it is insured would exceed its value on arrival.
        </p>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 50px">Increased Value Clause</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.1</p>
          <p style="padding-left: 10px">
            If any Increased Value insurance is effected by the Assured on the
            cargo insured herein the agreed value of the cargo shall be deemed
            to be increased to the total amount insured under this insurance and
            all Increased Value insurances covering the loss, and liability
            under this insurance shall be in such proportion as the sum insured
            herein bears to such total amount insured.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.2</p>
          <p style="padding-left: 10px">
            Where this insurance is on Increased Value the following clause
            shall apply: <br />
            The agreed value of the cargo shall be deemed to be equal to the
            total amount insured under the primary insurance and all Increased
            Value insurances covering the loss and effected on the cargo by the
            Assured, and liability under this insurance shall be in such
            proportion as the sum insured herein bears to such total amount
            insured. <br />
          </p>
        </div>

        <p style="padding-left: 60px">
          In the event of claim the Assured shall provide the Underwriters with
          evidence of the amounts insured under all other insurances.
        </p>

        <h3><b>BENEFIT OF INSURANCE</b></h3>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 40px"><b>Not to Inure Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          This insurance shall not inure to the benefit of the carrier or other
          bailee.
        </p>

        <h3><b>MINIMISING LOSSES</b></h3>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 40px"><b>Duty of Assured Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is the duty of the Assured and their servants and agents in respect
          of loss recoverable hereunder
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>16.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting minimizing such loss, and
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>16.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly preserved and exercised and the
                Underwriters will, in addition to any loss recoverable
                hereunder, reimburse the Assured for any charges properly and
                reasonably incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 40px"><b>Waiver Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          Measures taken by Assured or the Underwriters with the subject of
          saving, protecting or recovering the subject-matter insured shall not
          be considered as a waiver or acceptance of abandonment or otherwise
          prejudice the rights of either party.
        </p>

        <h3><b>AVOIDANCE OF DELAY</b></h3>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 40px"><b>Reasonable Despatch Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is a condition of this insurance that the Assured shall act with
          reasonable dispatch in all circumstances within their control.
        </p>

        <h3><b>LAW AND PRACICE</b></h3>
        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 40px">
            <b>English Law and Practice Clause</b>
          </p>
        </div>

        <p style="padding-left: 50px">
          This insurance is subject to English and practice.
        </p>

        <p>
          <b
            >NOTE: It is necessary for the Assured when they become aware of an
            event which is “held covered” under this insurance to give prompt
            notice to the Underwriters and the right to such cover is dependent
            upon compliance with this obligation.<br />
            SPECIAL NOTE: - This insurance does not cover loss damage or expense
            caused by embargo, or by rejection prohibition or detention by the
            government of the country of import or their agencies or
            departments, but does not exclude loss of or damage to the
            subject-matter insured caused by risks insured hereunder and
            sustained prior to any such embargo rejection prohibition or
            detention.
          </b>
        </p>
      </section>

      <section v-if="singleBusiness.typeofCover == 'IBOC'">
        <h3 style="text-align: center">
          1/2/83
          <span>INSTITUTE BULK OIL CLAUSES<br /> </span>
        </h3>

        <p><b>RISKS COVERED</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px"><b>Risks Clause</b></p>
        </div>

        <div style="display: flex; padding-left: 10px">
          <p></p>
          <p style="padding-left: 40px">
            This insurance covers, except as provided in Clauses 4, 5, 6 and 7
            below
          </p>
        </div>

        <div style="display: flex; padding-left: 30px">
          <p>1.1</p>
          <p style="padding-left: 40px">
            loss of or contamination of the subject-matter insured reasonably
            attributable to
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.1.1</p>
          <p style="padding-left: 40px">fire or explosion</p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.1.2</p>
          <p style="padding-left: 40px">
            vessel or craft being stranded grounded sunk or capsized
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.1.3</p>
          <p style="padding-left: 40px">
            collision or contract of vessel or craft with any external object
            other than water
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.1.4</p>
          <p style="padding-left: 40px">
            discharge of cargo at a port or place of distress
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.1.5</p>
          <p style="padding-left: 40px">
            earthquake volcanic eruption or lightning,
          </p>
        </div>

        <div style="display: flex; padding-left: 30px">
          <p>1.2</p>
          <p style="padding-left: 40px">
            loss of or contamination of the subject-matter insured caused by
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.2.1</p>
          <p style="padding-left: 40px">general average sacrifice</p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.2.2</p>
          <p style="padding-left: 40px">jettison</p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.2.3</p>
          <p style="padding-left: 40px">
            leakage from connecting pipelines in loading transshipment or
            discharge
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.2.4</p>
          <p style="padding-left: 40px">
            negligence of Master Officers or Crew in pumping cargo ballast or
            fuel,
          </p>
        </div>

        <div style="display: flex; padding-left: 40px">
          <p>1.2.4</p>
          <p style="padding-left: 40px">
            negligence of Master Officers or Crew in pumping cargo ballast or
            fuel,
          </p>
        </div>

        <div style="display: flex; padding-left: 30px">
          <p>1.3</p>
          <p style="padding-left: 40px">
            contamination of the subject-matter insured resulting from stress
            weather.
          </p>
        </div>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px"><b>General Average Clause </b></p>
        </div>

        <p style="padding-left: 60px">
          This insurance covers general average and salvage charges, adjusted or
          determined according to the contract of affreightment and/or the
          governing law and practice, incurred to avoid or in connection with
          the avoidance of loss from any cause except those excluded in Clause
          4, 5, 6 and 7 or elsewhere in this insurance.
        </p>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px">
            <b>“Both to Blame Collision” Clause </b>
          </p>
        </div>

        <p style="padding-left: 60px">
          This insurance is extended to indemnify the Assured against such
          proportion of liability under the contract of affreightment “Both to
          Blame Collision” Clause as is in respect of a loss recoverable
          hereunder. In the event of any claim by ship-owners under the said
          Clause the Assured agreed to notify the Underwriters who shall have
          the right, at their own cost and expense, to defend the Assured
          against such claim.
        </p>

        <h3><b>EXCLUSIONS</b></h3>

        <div>
          <div style="display: flex">
            <p>4</p>
            <p style="padding-left: 50px">General Exclusions Clause</p>
          </div>

          <p style="padding-left: 60px">
            In no case shall this insurance cover
          </p>

          <div style="display: flex; padding-left: 20px">
            <p>4.1</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.2</p>
            <p style="padding-left: 10px">
              loss damage or expense attributable to willful misconduct of the
              Assured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.3</p>
            <p style="padding-left: 10px">
              loss damage or expense caused by inherent vice or nature of the
              subject-matter insured
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.4</p>
            <p style="padding-left: 10px">
              loss damage or expenses proximately caused by delay, even though
              the delay be caused by a risk insured against (except expenses
              payable under Clause 2 above)
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.5</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from insolvency or financial
              default of the managers charterers or operators of the vessel
            </p>
          </div>
          <div style="display: flex; padding-left: 20px">
            <p>4.6</p>
            <p style="padding-left: 10px">
              loss damage or expense arising from the use of any weapon of war
              employing atomic or nuclear fission and/or fusion or other like
              reaction or radioactive force or matter.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 40px">
            <b>Unseaworthiness and Unfitness Exclusion Clause</b>
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>5.1</p>
          <p style="padding-left: 10px">
            In no case shall this insurance cover loss damage or expense arising
            from <br />
            unseaworthiness of vessel or craft, <br />
            unfitness of vessel craft conveyance container or unfitness, at the
            <br />
            time the subject-matter insured is loaded therein.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p></p>
          <p style="padding-left: 10px">
            Where the Assured or their servants are privy to such
            unseaworthiness or unfitness, at the time the subject-matter insured
            is loaded therein.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>5.2</p>
          <p style="padding-left: 10px">
            The Underwriters waive any breach of the implied warranties of
            seaworthiness of the ship and fitness of the ship to carry the
            subject- matter insured to destination, unless the Assured or their
            servants are privy to such unseaworthiness or unfitness.
          </p>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 50px"><b>War Risk Exclusion Clause</b></p>
        </div>

        <p style="padding-left: 60px">
          In no case shall this insurance cover loss damage or expense caused by
        </p>

        <div style="display: flex; padding-left: 20px">
          <p>6.1</p>
          <p style="padding-left: 10px">
            war civil war revolution rebellion insurrection, or civil strife
            arising therefrom, or any hostile act by or against a belligerent
            power
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>6.2</p>
          <p style="padding-left: 10px">
            capture seizure arrest restraint or detainment (piracy excepted),
            and the consequences thereof or any attempt thereat
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>6.3></p>
          <p style="padding-left: 10px">
            derelict mines torpedoes bombs or other derelict weapons of war.
          </p>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 40px"><b>Strikes Exclusion Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          In no case shall this insurance cover loss damage or expense
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by strikers, locked-out workmen, or persons taking part
                in labour disturbances, riots or civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                resulting from strikes, lock-outs, labour disturbance, riots or
                civil commotions
              </p>
            </div>
          </div>
        </div>
        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                caused by any terrorist or any person acting from a political
                motive.
              </p>
            </div>
          </div>
        </div>

        <h3><b>DURATION</b></h3>

        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 50px"><b>Transit Clause</b></p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>8.1</p>
          <p style="padding-left: 10px">
            This insurance attaches as the subjection-matter insured leaves
            tanks for the purpose of loading at the place named herein for the
            commencement of the transit, continues during the ordinary course if
            transit and terminates
          </p>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.1</p>
            <p style="padding-left: 10px">
              as the subject-matter insured enters tanks on discharge to place
              of storage or to storage vessel at the destination named herein,
              or
            </p>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <p>8.1.2</p>
            <p style="padding-left: 10px">
              on the expiry of 30 days of arrival of the vessel at the
              destination named herein whichever shall first occur.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.2</p>
            <p style="padding-left: 10px">
              If, after discharge from the oversea vessel into craft at the
              final port or place of discharge, but prior to the termination of
              this insurance under 8.1 above, the subject-matter insured or any
              part thereof is to be forwarded to a destination other that to
              which it is insured hereunder, the insurance on the subject-matter
              insured or such part thereof shall not extend beyond the
              commencement of transit to such other destination, unless
              otherwise agreed by the Underwriters upon receipt of prompt notice
              from the Assured.
            </p>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <p>8.2</p>
            <p style="padding-left: 10px">
              Subject to prompt notice being given to the Underwriters and an
              additional premium if required by them this insurance shall remain
              in force (until terminated under 8.1 or 8.2 above and subject to
              the provisions of Clause 9 below) during delay beyond the control
              of the Assured, any deviation, forced discharge, reshipment or
              transshipment and during any other variation of the adventure
              provided such other variation is beyond the control of the
              Assured.
            </p>
          </div>
        </div>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 50px">
            <b>Termination of Contract of Carriage</b> Clause
          </p>
        </div>

        <p style="padding-left: 60px">
          If owing to circumstances beyond the control of the Assured either the
          contract of carriage is terminated at a port or place other than the
          destination named therein or the transit is otherwise terminated
          before delivery of the goods as provided for in Clause 8 above, then
          this insurance shall also terminate unless prompt notice is given to
          the Underwriters and continuation of cover is required then the
          insurance shall remain in force, subject to an additional premium if
          required by the Underwriters, either
        </p>

        <div style="display: flex; padding-left: 20px">
          <p>9.1</p>
          <p style="padding-left: 10px">
            until the goods are sold and delivered at such port or place, or,
            unless otherwise specially agreed, until the expiry of 30 days after
            arrival of the goods hereby insured at such port or place, whichever
            shall first occur, or
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>9.2</p>
          <p style="padding-left: 10px">
            if the goods are forwarded within the said period of 30 days (or any
            agreed extension thereof) to the destination named herein or to any
            other destination, until terminated in accordance with the
            provisions of Clause 8 above.
          </p>
        </div>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 50px">Change of Voyage Clause</p>
        </div>

        <p style="padding-left: 60px">
          Where, after the attachment of this insurance, the destination is
          changed by the Assured, held covered at a premium and on conditions to
          be arranged subject to prompt notice being given to the Underwriters.
        </p>

        <h3>CLAIMS</h3>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 50px">Insurable Interest Clause</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.1</p>
          <p style="padding-left: 10px">
            In order to recover under this insurance the Assured must have an
            insurable interest in the subject matter insured at the time of the
            loss.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>11.2</p>
          <p style="padding-left: 10px">
            Subject to 11.1 above, the Assured shall be entitled to recover for
            insured loss occurring during the period covered by this insurance,
            notwithstanding that the loss occurred before the contract of
            insurance was concluded, unless the Assured were aware of the loss
            and the Underwriters were not.
          </p>
        </div>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 50px">
            Forwarding Charges ClauseInsurable Interest Clause
          </p>
        </div>

        <p style="padding-left: 60px">
          Where, as a result of the operation of a risk covered by this
          insurance, the insured transit is terminated at a port or place other
          than that to which the subject matter is covered under this insurance,
          the Underwriters will reimburse the Assured for any extra charges
          properly and reasonably incurred in unloading storing and forwarding
          the subject matter to the destination to which it is insured
          hereunder.
        </p>

        <p style="padding-left: 60px">
          This clause 12, which does not apply to general average or salvage
          charges, shall be subject to the exclusions contained in Clause 4, 5,
          6 and 7 above, and shall not include charges arising from the fault
          negligence insolvency or financial default of the Assured or their
          servants.
        </p>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 50px">
            <b>Constructive Total Loss Clause.</b>
          </p>
        </div>

        <p style="padding-left: 60px">
          No claim for Constructive Total Loss shall be recoverable hereunder
          unless the subject matter is reasonably abandoned either on account of
          its actual total loss appearing to be unavoidable or because the cost
          of recovering, reconditioning and forwarding the subject matter to the
          destination to which it is insured would exceed its value on arrival.
        </p>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 50px">Increased Value Clause</p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.1</p>
          <p style="padding-left: 10px">
            If any Increased Value insurance is effected by the Assured on the
            cargo insured herein the agreed value of the cargo shall be deemed
            to be increased to the total amount insured under this insurance and
            all Increased Value insurances covering the loss, and liability
            under this insurance shall be in such proportion as the sum insured
            herein bears to such total amount insured.
          </p>
        </div>

        <div style="display: flex; padding-left: 20px">
          <p>14.2</p>
          <p style="padding-left: 10px">
            Where this insurance is on Increased Value the following clause
            shall apply: <br />
            The agreed value of the cargo shall be deemed to be equal to the
            total amount insured under the primary insurance and all Increased
            Value insurances covering the loss and effected on the cargo by the
            Assured, and liability under this insurance shall be in such
            proportion as the sum insured herein bears to such total amount
            insured. <br />
          </p>
        </div>

        <p style="padding-left: 60px">
          In the event of claim the Assured shall provide the Underwriters with
          evidence of the amounts insured under all other insurances.
        </p>

        <h3><b>BENEFIT OF INSURANCE</b></h3>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 40px"><b>Adjustment Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          Claims for leakage and shortage recoverable under this insurance are
          to be adjusted as follows:
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>15.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The amount recoverable shall be the proportionate insured value
                of the volume of oil lost, to be ascertained by a comparison of
                the gross volume certified as having left tanks for loading on
                the vessel with the gross volume certified as having been
                delivered to tanks at the termination of the transit, except
                that where the contract of sale is based on weight and not on
                volume the amount recoverable may be calculated on a weight
                basis from such certified quantities.

                <br />

                The term “gross volume” in this Clause 15.1 means total volume
                without deduction of sediment and water content and free water,
                except to the extent that the amount of water can be shown by
                the Assured to have increased abnormally during the insured
                transit as a result of the operation of a risk covered by this
                insurance.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>15.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Adjustment shall be made to the calculation under 15.1 above to
                eliminate any change in volume caused by variation in
                temperature and any apparent change in quantity arising from the
                use of inconsistent procedures in determine the certified
                quantities.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>15.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where this insurance provides for an excess to be applied to
                claims for leakage or shortage, such excess shall be deemed to
                include ordinary loss in weight or volume except when caused by
                variation in temperature or setting out of water. Where there is
                no such provision, the amount recoverable in accordance with
                Clause 15.1 and 15.2 shall be subject to reduction for any
                ordinary loss excluded by Clause 4.2 above.
              </p>
            </div>
          </div>
        </div>

        <h3><b>BENEFIT OF INSURANCE</b></h3>

        <div style="display: flex">
          <p>16</p>
          <p style="padding-left: 40px"><b>Not to Inure Clause</b></p>
        </div>

        <h3><b>MINIMISING LOSSES</b></h3>

        <div style="display: flex">
          <p>17</p>
          <p style="padding-left: 40px"><b>Duty of Assured Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is the duty of the Assured and their servants and agents in respect
          of loss recoverable hereunder
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>17.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting minimizing such loss, and
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>17.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly preserved and exercised and the
                Underwriters will, in addition to any loss recoverable
                hereunder, reimburse the Assured for any charges properly and
                reasonably incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>18</p>
          <p style="padding-left: 40px"><b>Waiver Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          Measures taken by Assured or the Underwriters with the subject of
          saving, protecting or recovering the subject-matter insured shall not
          be considered as a waiver or acceptance of abandonment or otherwise
          prejudice the rights of either party.
        </p>

        <h3><b>AVOIDANCE OF DELAY</b></h3>

        <div style="display: flex">
          <p>19</p>
          <p style="padding-left: 40px"><b>Reasonable Despatch Clause</b></p>
        </div>

        <p style="padding-left: 50px">
          It is a condition of this insurance that the Assured shall act with
          reasonable dispatch in all circumstances within their control.
        </p>

        <h3><b>LAW AND PRACICE</b></h3>
        <div style="display: flex">
          <p>20</p>
          <p style="padding-left: 40px">
            <b>English Law and Practice Clause</b>
          </p>
        </div>

        <p style="padding-left: 50px">
          This insurance is subject to English and practice.
        </p>

        <p>
          <b
            >NOTE: - It is necessary for the Assured when they become aware of
            an event which is "held covered" under this insurance to give prompt
            notice to the Underwriters and the right to such cover is dependent
            upon compliance with this obligation.
          </b>
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute War Clauses (Cargo)'
          )
        "
      >
        <h3>
          <span style="margin-right: 240px">1/01/09</span> INSTITUTE WAR CLAUSES
          (CARGO)
        </h3>

        <h3>RISKS COVERED</h3>

        <p style="padding-left: 0px"><b>Risks</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">
            This insurance covers, except excluded by the provision of Clause 3
            and 4 below, loss of or damage to the subject-matter insured caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                war civil war revolution rebellion insurrection, or civil strife
                arising therefrom, or any hostile act by or against a
                belligerent power
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                capture seizure arrest restraint or detainment, arising from
                risks covered under 1.1 above, and the consequences thereof or
                any attempt thereat
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Derelict mines torpedoes bombs or other derelict weapons of war.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>General Average</b></p>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px">
            This insurance covers general average and salvage charges, adjusted
            or determined according to the contract of carriage and / or the
            governing law and practice, incurred to avoid or in connection with
            the avoidance of loss from a risk covered under these Clauses.
          </p>
        </div>

        <h3>RISKS COVERED</h3>

        <p style="padding-left: 0px"><b>Exclusions</b></p>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense attributable to willful misconduct of the
                Assured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                ordinary leakage, ordinary loss in weight or volume, or ordinary
                wear and tear of the subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insufficiency or unsuitability
                of packing or preparation of the subject-matter insured to
                withstand the ordinary incidents of the insured transit where
                such packing or preparation is carried out by the Assured or
                their employees or prior to the attachment of this insurance (
                for the purpose of these Clauses “packing” shall be deemed to
                include stowage in a container and “employees” shall not include
                independent contractors)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by inherent vice or nature of the
                subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by delay, even though the delay be
                caused by a risk insured against (except expenses payable under
                Clause 2 above)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.6</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insolvency or financial default
                of the owners managers charterers or operators of the vessel
                where, at the time of loading course of business should be
                aware, that such insolvency or financial default could prevent
                the normal prosecution of the voyage <br />
                This exclusion shall not apply where the contract of insurance
                has been assigned to the party claiming hereunder who has bought
                or agreed to buy the subject-matter insured in good faith under
                a binding contract
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.7</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insufficiency or unsuitability
                of packing or preparation of the subject-matter insured to
                withstand the ordinary incidents of the insured transit where
                such packing or preparation is carried out by the Assured or
                their employees or prior to the attachment of this insurance (
                for the purpose of these Clauses “packing” shall be deemed to
                include stowage in a container and “employees” shall not include
                independent contractors)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.8</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense directly or indirectly caused by or
                arising from any hostile use of any weapon or device employing
                atomic fission and/or fusion or other like reaction or
                radioactive force or matter.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In no case shall this insurance cover loss damage or expense
                arising from
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>4.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                unseaworthiness of vessel or craft or unfitness of vessel or
                craft for the safe carriage of the subject-matter insured, where
                the Assured are privy to such unseaworthiness or unfitness, at
                the time of the subject-matter insured is loaded therein
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>4.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                unfitness of container or conveyance for the safe carriage of
                the subject-matter insured, where loading therein or thereon is
                carried out prior to attachment of this insurance or by the
                assured or their employees and they are privy to such unfitness
                at the time of loading.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Exclusion 4.1.1 above shall not apply where the contract of
                insurance has been assigned to the party claiming hereunder who
                has bought or agreed to buy the subject-matter insured in good
                faith under a binding contract.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The insurers waive any breach of the implied warranties of
                seaworthiness of the ship and fitness of the ship to carry the
                subject-matter insured to destination.
              </p>
            </div>
          </div>
        </div>

        <h3>DURATION</h3>

        <p style="padding-left: 0px"><b>Transit Clause</b></p>

        <div style="display: flex">
          <p>5</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">This Insurance</p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                attaches only as the subject-matter insured and as to any part
                is loaded on an ovearsea vessel <br />
                And
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                terminates, subject to 5.2 and 5.3 below, either as the
                subject-matter insured and as to any part as that part is
                discharge from an oversea vessel at the final port or place of
                discharge, <br />
                Or<br />
                On expiry of 15 days counting from midnight of the day of
                arrival of the vessel at the final port or place <br />
                of discharge,<br />
                Whichever shall first occur;<br />
                Nevertheless,<br />
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                terminates, subject to 5.2 and 5.3 below, either as the
                subject-matter insured as to any part as that part is thereafter
                discharged from the vessel at the final (or substituted) port or
                place of discharge, <br />
                Or <br />
                On expiry of 15 days counting from midnight of the day of
                re-arrival of the vessel at the final port or <br />place of
                discharge or arrival of the vessel at a substituted port or
                place of discharge, whichever shall <br />first occur
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If during the insured voyage the oversea arrives at an
                intermediate port or place to discharge the subject-matter
                insured for on-carriage by oversea vessel or by aircraft, or the
                subject-matter insured is discharged from the vessel at a port
                or place of refuge, then, subject to 5.3 below and to an
                additional premium if required, this insurance continues until
                the expiry of 15 days counting from midnight of the day of
                arrival of the vessel at such port or place, but thereafter
                reattaches as the subject-matter insured and as to any part as
                that part is loaded on an on-carrying oversea vessel or
                aircraft. During the period of 15 days the insurance remains in
                force after discharge only whilst the subject-matter insured and
                as to any part as that as that part is at such port or place. If
                the subject-matter insured is on carried within the said period
                of 15 days or if the insurance reattaches as provided in this
                Clause 5.2
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>5.2.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where the on-carriage is by oversea vessel this insurance
                continues subject to the terms of these Clauses, Or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>5.2.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where the on-carriage is by aircraft, the current institute war
                Clauses (Air Cargo) (excluding sending’s by post) shall be
                deemed to form part of the contract of insurance and shall apply
                to the on-carriage by air.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If the voyage in the contract of carriage is terminated at a
                port or place other than the destination agreed therein, such
                port or place shall be deemed the final port of discharge and
                this insurance terminates in accordance with 5.1.2. if the
                subject-matter insured is subsequently reshipped to the original
                or any other destination, then provided notice is given to the
                insurers before the commencement of such further transit and
                subject to an additional premium, this insurance reattaches
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>5.3.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                in the case of the subject-matter insured having been
                discharged, as the subject-matter insured and as to any part as
                that part is loaded on the on-carrying vessel for the voyage;
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>5.3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                in the case of the subject-matter not having been discharged,
                when the vessel sails from such deemed final port of discharge;
                thereafter this insurance terminates in accordance with 5.1.4.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The insurance against the risks of mines and derelict torpedoes,
                floating or submerged, is extended whilst the subject-matter
                insured or any part thereof is on craft whilst to or from the
                oversea vessel, but in no case beyond the expiry of 60 days
                after discharge from the oversea vessel unless otherwise
                specially agreed by the insurers.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to prompt notice to insurers, and to an additional
                premium if required, this insurance shall remain in force within
                the provisions of these Clauses during any deviation, or any
                variation of the adventure arising from the exercise of a
                liberty granted to carriers under the contract of carriage. (For
                the purpose of Clause 5) <b></b> “arrival” shall be deemed to
                mean that the vessel is anchored, moored or otherwise secured at
                a berth or occurred within the harbor Authority area. If such a
                berth or place is not available, arrival is deemed to have
                occurred when the vessel first anchors, mores or otherwise
                secures either at or off the intended port or place of discharge
                <b></b>
                “Oversea vessel” shall be deemed to mean a vessel carrying the
                subject-matter from one port or place to another where such
                voyage involves a sea passage by that vessel)
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>Change of Voyage</b></p>

        <div style="display: flex">
          <p>6</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where, after attachment of this insurance, the destination is
                changed by the Assured, this must be notified promptly to
                insurers for rates and terms to be agreed. Should a loss occur
                prior to such agreement being obtained cover may be provided but
                only if cover would have been available at a reasonable
                commercial market rate on reasonable market terms.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                where the subject-matter insured commences the transit
                contemplated by this insurance (in accordance with Clause 5.1),
                but, without the knowledge of the Assured or their employees the
                ship sails for another destination, this insurance will
                nevertheless be deemed to have attached at commencement of such
                transit.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 30px">
            <b
              >Anything contained in this contract which is inconsistent with
              Clause 3.7, 3.8 or 5 shall, to the extent of such inconsistency,
              be null and void.</b
            >
          </p>
        </div>

        <h3>CLAIMS</h3>

        <p style="padding-left: 0px"><b>Insurable Interest</b></p>

        <div style="display: flex">
          <p>8</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In order to recover under this insurance the Assured must have
                an insurable interest in the subject-matter insured at the time
                of the loss.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to Clause 8.1 above, the Assured shall be entitled to
                recover for insured loss occurring during the period covered by
                this insurance, notwithstanding that the loss occurred before
                the contract of insurance was concluded, unless the Assured were
                aware of the loss and the insurers were not.
              </p>
            </div>
          </div>
        </div>

        <p><b>Increased Value</b></p>

        <div style="display: flex">
          <p>9</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If any increased Value insurance is effected by the Assured on
                the subject-matter insured under this insurance the agreed value
                of this subject-matter insured be deemed to be increased to the
                total amount insured under this insurance and all increased
                Value insurances covering the loss, and liability under this
                insurance shall be in such proportion as the sum insured under
                this insurance bears to such total amount insured. In the event
                of claim the Assured shall provide the insurers with evidence of
                the amounts insured under all other insurances.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where this insurance is on Increased Value the following clause
                shall apply: The agreed value of the subject-matter insured
                shall be deemed to be equal to the total amount insured under
                the primary insurance and all Increased Value insurances
                covering the loss and effected on the subject-matter insured by
                the Assured, and liability under this insurance shall be in such
                proportion as the sum insured under this insurance bears to such
                total amount insured. In the event of claim the Assured shall
                provide the insurers with evidence of the amounts insured under
                all other insurances.
              </p>
            </div>
          </div>
        </div>

        <h3>BENEFIT OF INSURANCE</h3>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 20px">This Insurance</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                covers the Assured which includes the person claiming indemnity
                either as the person by on whose behalf the contract of
                insurance was effected or as an assignee,
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                shall not extend to or otherwise benefit the carrier or other
                bailee.
              </p>
            </div>
          </div>
        </div>

        <h3>MINIMISING LOSSES</h3>

        <p style="padding-left: 0px"><b>Duty of Assured</b></p>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 20px">
            It is the duty of the Assured and their employees and agents in
            respect to loss recoverable hereunder
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>11.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting or minimizing such loss, And
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>11.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly preserved and exercised And the
                insurers will, in addition to any loss recoverable hereunder,
                reimburse the Assured for any charges properly and reasonably
                incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>Waiver</b></p>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 20px">
            Measures taken by the Assured or the insurers with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either party
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 20px">
            It is a condition of this insurance that the Assured shall act with
            reasonable dispatch in all circumstances within their control.
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 20px">
            This insurance is subject to English law and practice
          </p>
        </div>

        <p>
          <b>
            NOTE: - where a reattachment of cover is requested under Clause 5,
            or a change of destination is notified under Clause 6, there is an
            obligation to give prompt notice to the insurers and the right to
            such cover is dependent upon compliance with this obligation
          </b>
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute Strike Clauses (Cargo)'
          )
        "
      >
        <h3>
          <span style="margin-right: 240px">1/01/09</span>INSTITUTE STRIKES
          CLAUSES (CARGO)
        </h3>

        <h3>RISKS COVERED</h3>

        <p style="padding-left: 0px"><b>Risks</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">
            This insurance covers, except excluded by the provision of Clause 3
            and 4 below, loss of or damage to the subject-matter insured caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Strikers, locked-out workmen, or persons taking part in labour
                disturbances, riots or civil commotions
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Any act of terrorism being an act of any person acting on behalf
                of, or in connection with, any organization which carries out
                activities directed towards the overthrowing or influencing, by
                force or violence, of any government whether or not legally
                constituted
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Any person acting from a political, ideological or religious
                motive.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>General Average</b></p>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 40px">
            This insurance covers general average and salvage charges, adjusted
            or determined according to the contract of carriage and/ or the
            governing law and practice, incurred to avoid or in connection with
            the avoidance of loss from a risk covered under these Clauses.
          </p>
        </div>

        <h3>EXCLUSIONS</h3>

        <p style="padding-left: 0px"><b>Exclusions</b></p>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 40px">
            In no case shall this insurance cover
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense attributable to willful misconduct of the
                Assured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                ordinary leakage, ordinary loss in weight or volume, or ordinary
                wear and tear of the subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insufficiency or unsuitability
                of packing or preparation of the subject-matter insured to
                withstand the ordinary incidents of the insured transit where
                such packing or preparation is carried out by the Assured or
                their employees or prior to the attachment of this insurance
                (for the purpose of this Clause 3.3 “packing” shall be deemed to
                include stowage in a container and “employees” shall not include
                independent contractors)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by inherent vice or nature of the
                subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by delay, even though the delay be
                caused by a risk insured against (except expenses payable under
                Clause 2 above)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.6</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insolvency or financial default
                of the owners managers charterers or operators of the vessel
                where, at the time of loading the subject-matter insured on
                board the vessel , the Assured are aware, or in the ordinary
                course of business should be aware, that such insolvency or
                financial default could prevent the normal prosecution of the
                voyage <br />
                This exclusion shall not apply where the contract of insurance
                has been assigned to the party claiming hereunder who has bought
                or agreed to buy the subject-matter insured in good faith under
                a binding contract
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.7</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from the absence shortage or
                withholding of labour of any description whatsoever resulting
                from any strike, lockout, labour disturbance, riot or civil
                commotion
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.8</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                any claim based upon loss or frustration of the voyage or
                adventure
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.9</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                3.9 loss damage or expense directly or indirectly caused by or
                arising from the use of any weapon or device employing atomic or
                nuclear fission and /or fusion or other like reaction or
                radioactive force or matter
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 40px"></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In no case shall this insurance cover loss damage or expense
                arising from
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>4.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                unseaworthiness of vessel or craft or unfitness of vessel or
                craft for the safe carriage of the subject-matter insured, where
                the Assured are privy to such unseaworthiness or unfitness, at
                the time of the subject-matter insured is loaded therein
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>4.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                unfitness of container or conveyance for the safe carriage of
                the subject-matter insured, where loading therein or thereon is
                carried out prior to attachment of this insurance or by the
                assured or their employees and they are privy to such unfitness
                at the time of loading.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Exclusion 4.1.1 above shall not apply where the contract of
                insurance has been assigned to the party claiming hereunder who
                has bought or agreed to buy the subject-matter insured in good
                faith under a binding contract.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The insurers waive any breach of the implied warranties of
                seaworthiness of the ship and fitness of the ship to carry the
                subject-matter insured to destination.
              </p>
            </div>
          </div>
        </div>

        <h3>DURATION</h3>

        <p style="padding-left: 0px"><b>Transit Clause</b></p>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 40px">
            Subject to Clause 11 below, this insurance attaches from the time
            the subject-matter insured is first moved in the warehouse or at the
            place of storage (at the place named in the contract of insurance)
            for the purpose of the immediate loading into or onto the carrying
            vehicle or other conveyance for the commencement of transit, <br />

            Continues during the ordinary course of transit and terminates
            either
          </p>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on completion of unloading from the carrying vehicle or other
                conveyance in or at the final warehouse or place of storage at
                the destination named in the contract of insurance.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on completion of unloading from the carrying vehicle or other
                conveyance in or at any other warehouse or place of storage,
                whether prior to or at the destination named in the contract of
                insurance, which the Assured or their employees elect to use
                either for storage other than in the ordinary course of transit
                or for allocation or distribution, or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                When the Assured or their employees elect to use any carrying
                vehicle or other conveyance or any container for storage other
                than in the ordinary course of transit or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                On the expiry of 60 days after completion of discharge overside
                of the subject-matter insured from the oversea vessel at the
                final port of discharge, whichever shall first occur
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If, after discharge overside from the oversea vessel at the
                final port of discharge, but prior to termination of this
                insurance, the subject-matter insured is to be forwarded to a
                destination than that to which it is insured, this insurance,
                whilst remaining subject to termination as provided in Clauses
                8.1.1 to 8.1.4, shall not extend beyond the time the
                subject-matter insured is first moved for the purpose of the
                commencement of transit to such other destination.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                This insurance shall remain in force (subject to termination as
                provided for in Clause 8.1.1 to 8.1.4 above and to the
                provisions of Clause 9 below) during delay beyond the control of
                the Assured, any deviation, forced discharge, reshipment or
                transshipment and during any variation of the adventure arising
                from the exercise of a liberty granted to carriers under the
                contract of carriage
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The insurance against the risks of mines and derelict torpedoes,
                floating or submerged, is extended whilst the subject-matter
                insured or any part thereof is on craft whilst to or from the
                oversea vessel, but in no case beyond the expiry of 60 days
                after discharge from the oversea vessel unless otherwise
                specially agreed by the insurers.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to prompt notice to insurers, and to an additional
                premium if required, this insurance shall remain in force within
                the provisions of these Clauses during any deviation, or any
                variation of the adventure arising from the exercise of a
                liberty granted to carriers under the contract of carriage. (For
                the purpose of Clause 5) <b></b> “arrival” shall be deemed to
                mean that the vessel is anchored, moored or otherwise secured at
                a berth or occurred within the harbor Authority area. If such a
                berth or place is not available, arrival is deemed to have
                occurred when the vessel first anchors, mores or otherwise
                secures either at or off the intended port or place of discharge
                <b></b>
                “Oversea vessel” shall be deemed to mean a vessel carrying the
                subject-matter from one port or place to another where such
                voyage involves a sea passage by that vessel)
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px">
          <b>Termination of Contract of Carriage</b>
        </p>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 10px">
            if owing to circumstances beyond the control of the Assured either
            the contract of carriage is terminated at a port or place other than
            the destination named therein or the transit is otherwise terminated
            before unloading of the subject-matter insured as provided for in
            Clause 8 above, then this insurance shall also terminate unless
            prompt notice is given to the insurers and continuation of cover is
            requested when this insurance shall remain in force, subject to an
            additional premium if required by the insurers, either
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p>
                until the subject-matter insured is sold and delivered at such
                port or place, or, unless otherwise specially agreed, until the
                expiry of 60 days after arrival of the subject-matter insured at
                such or place, whichever shall first occur, Or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                if the subject-matter insured is forwarded within the said
                period of 60 days (or any agreed extension thereof) to the
                destination named in the contract of insurance or to any other
                destination, until terminated in accordance with the provisions
                of Clause 5 above.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>Change of Voyage</b></p>

        <div style="display: flex">
          <p>7</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where, after attachment of this insurance, the destination is
                changed by the Assured, this must be notified promptly to
                insurers for rates and terms to be agreed. Should a loss occur
                prior to such agreement being obtained cover may be provided but
                only if cover would have been available at a reasonable
                commercial market rate on reasonable market terms.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                where the subject-matter insured commences the transit
                contemplated by this insurance (in accordance with Clause 5.1),
                but, without the knowledge of the Assured or their employees the
                ship sails for another destination, this insurance will
                nevertheless be deemed to have attached at commencement of such
                transit.
              </p>
            </div>
          </div>
        </div>

        <h3>CLAIMS</h3>

        <p style="padding-left: 0px"><b>Insurable Interest</b></p>

        <div style="display: flex">
          <p>8</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In order to recover under this insurance the Assured must have
                an insurable interest in the subject-matter insured at the time
                of the loss.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to Clause 8.1 above, the Assured shall be entitled to
                recover for insured loss occurring during the period covered by
                this insurance, notwithstanding that the loss occurred before
                the contract of insurance was concluded, unless the Assured were
                aware of the loss and the insurers were not.
              </p>
            </div>
          </div>
        </div>

        <p><b>Increased Value</b></p>

        <div style="display: flex">
          <p>9</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If any increased Value insurance is effected by the Assured on
                the subject-matter insured under this insurance the agreed value
                of this subject-matter insured be deemed to be increased to the
                total amount insured under this insurance and all increased
                Value insurances covering the loss, and liability under this
                insurance shall be in such proportion as the sum insured under
                this insurance bears to such total amount insured. In the event
                of claim the Assured shall provide the insurers with evidence of
                the amounts insured under all other insurances.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where this insurance is on Increased Value the following clause
                shall apply: The agreed value of the subject-matter insured
                shall be deemed to be equal to the total amount insured under
                the primary insurance and all Increased Value insurances
                covering the loss and effected on the subject-matter insured by
                the Assured, and liability under this insurance shall be in such
                proportion as the sum insured under this insurance bears to such
                total amount insured. In the event of claim the Assured shall
                provide the insurers with evidence of the amounts insured under
                all other insurances.
              </p>
            </div>
          </div>
        </div>

        <h3>BENEFIT OF INSURANCE</h3>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 20px">This Insurance</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                covers the Assured which includes the person claiming indemnity
                either as the person by on whose behalf the contract of
                insurance was effected or as an assignee,
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                shall not extend to or otherwise benefit the carrier or other
                bailee.
              </p>
            </div>
          </div>
        </div>

        <h3>MINIMISING LOSSES</h3>

        <p style="padding-left: 0px"><b>Duty of Assured</b></p>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 20px">
            It is the duty of the Assured and their employees and agents in
            respect to loss recoverable hereunder
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>11.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting or minimizing such loss, And
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>11.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly preserved and exercised And the
                insurers will, in addition to any loss recoverable hereunder,
                reimburse the Assured for any charges properly and reasonably
                incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>Waiver</b></p>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 20px">
            Measures taken by the Assured or the insurers with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of either party
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 20px">
            It is a condition of this insurance that the Assured shall act with
            reasonable dispatch in all circumstances within their control.
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 20px">
            This insurance is subject to English law and practice
          </p>
        </div>

        <p>
          <b>
            NOTE: - where a reattachment of cover is requested under Clause 5,
            or a change of destination is notified under Clause 6, there is an
            obligation to give prompt notice to the insurers and the right to
            such cover is dependent upon compliance with this obligation
          </b>
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute War Clauses (Air)'
          )
        "
      >
        <h3>
          <span style="margin-right: 200px">1/01/82</span>(FOR USE ONLY WITH THE
          NEW MARINE POLICY FORM)
        </h3>
        <h3>
          <span style="margin-right: 300px"></span>INSTITUTE WAR CLAUSES (AIR
          CARGO)
        </h3>
        <h3>
          <span style="margin-right: 350px"></span
          ><b>(excluding sending by Post)</b>
        </h3>

        <h3>RISKS COVERED</h3>

        <p style="padding-left: 0px"><b>Risks</b></p>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 40px">
            This insurance covers, except excluded by the provision of Clause 3
            and 4 below, loss of or damage to the subject-matter insured caused
            by
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                War civil war revolution rebellion insurrection, or civil strife
                arising therefrom, or any hostile act by or against a
                belligerent power
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Capture seizure arrest restraint or detainment, arising from
                risks cover under1.1 above, and the consequences therefore or
                any attempt thereat
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                derelict mines torpedoes bombs or other derelict weapons of war.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 20px"><b>General Average</b></p>
        </div>

        <p style="padding-left: 40px">In no case shall this insurance cover</p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense attributable to willful misconduct of the
                Assured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                ordinary leakage, ordinary loss in weight or volume, or ordinary
                wear and tear of the subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by insufficiency or unsuitability
                of packing or preparation of the subject-matter insured (for the
                purpose of this Clause 2.3 ‘packing’shall be deemed to include
                stowage in container or liftvan but only when such stowage is
                carried out prior to attachment of this insurance or by the
                Assured or their servants)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by inherent vice or nature of the
                subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from unfitness of aircraft
                conveyance container or liftvan for the safe carriage of the
                subject-matter insured, where the Assured or their servants are
                privy to such unfitness at the time the subject-matter insured
                is loaded therein
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.6</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Loss damage or expense proximately caused by delay, even though
                the delay be caused by a risk insured against.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.7</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from insolvency or financial
                default of the owners mangers charterers or operators of the
                aircraft
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.8</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from any hostile use of any
                weapon of war employing atomic or nuclear fission and /or fusion
                or other like reaction or radioactive force or matter
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>2.9</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                any claim based upon loss of or frustration of the voyage or
                adventure
              </p>
            </div>
          </div>
        </div>

        <h3>DURATION</h3>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 20px"><b>Transit Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.1</p>
            </div>
            <div>
              <p style="padding-left: 20px">
                This insurance attaches from the time the subject-matter insured
                leaves the warehouse, premises or place of storage at the place
                named herein for the commencement of the transit, continues
                during the ordinary course of transit and terminates either
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>3.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on delivery to the Consignees’ or other final warehouse,
                premises or place of storage at the destination named herein
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>3.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on delivery to any other warehouse, premises or place of
                storage, whether prior to at the destination named herein, which
                the Assured elect to use either
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>3.1.2.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                for storage other than in the ordinary course of transit or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 60px">
          <div style="display: flex">
            <div>
              <p>3.1.2.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                for allocation or distribution or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>3.1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on the expiry of 30 days after unloading the subject-matter
                insured from the aircraft at the final place of discharge,
                whichever shall first occur.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                3.2 If, after unloading from the aircraft at the final place of
                discharge, but prior to termination of this insurance. The
                subject-matter insured is forwarded to a destination other than
                that to which it is insured hereunder, this insurance whilst
                remaining subject to termination as provided for above, shall
                not extend beyond the commencement of transit to such other
                destination.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                This insurance shall remain in force (subject to termination as
                provided for above and to the provisions of Clause 4 below)
                during delay beyond the control of the Assured, any deviation,
                force forced discharge, reshipment or transshipment and during
                any variation of the adventure arising from the exercise of a
                liberty granted to the air carriers under the contract of
                carriage.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 40px">
            <b>Termination of Contact of Carriage Clause</b>
          </p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            If owning to circumstance beyond the control of the Assured either
            the contract of carriage is terminated at a place other than the
            destination named therein or the transit is otherwise terminated
            before delivery of the subject-matter insured as provided for in
            Clause 3 above, then this insurance shall also terminate unless
            prompt notice is given to the Underwriters and continuation of cover
            is requested then the insurance shall remain in force, subject to an
            additional premium if required by the Underwriters, either
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                until the subject-matter is sold and delivered at such place or,
                unless otherwise specially agreed, until the expiry of 30 days
                after arrival of the subject-matter hereby insured at such
                place, whichever shall first occur,
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If the subject-matter is forward within the said period of 30
                days (or any agreed extension thereof) to the destination named
                herein or to any other destination, until terminated in
                accordance with the provisions of Clause 3 above.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>4.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                unseaworthiness of vessel or craft or unfitness of vessel or
                craft for the safe carriage of the subject-matter insured, where
                the Assured are privy to such unseaworthiness or unfitness, at
                the time of the subject-matter insured is loaded therein
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 20px"><b>Change of Voyage Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            Where, after attachment of this insurance, the destination is
            changed by the Assured, held covered at a premium and on conditions
            to be arranged subject to prompt notice being given to the
            Underwriters.
          </p>
        </div>

        <h3>CLAIMS</h3>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 20px"><b>Insurable Interest Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In order recover under this insurance the Assured must have an
                insurable interest in the subject-matter insured at the time of
                the loss.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to 8.1 above, the Assured shall be entitled to recover
                for insured loss occurring during the period covered by this
                insurance, notwithstanding that the loss occurred before the
                contract of insurance was concluded, unless the Assured were
                aware of the loss and the Underwriters were not.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 20px"><b>Increased Value Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If any Increased Value insurance is effected by the Assured on
                the cargo insured herein the agreed value of the cargo shall be
                deemed to be increased to the total amount insured under this
                insurance and all Increased Value insurances covering the loss,
                and liability under this insurance shall be in such proportion
                as the sum insured herein bears to such total amount insured.
                <br />
                In the event of claim the Assured shall provide the Underwriters
                with evidence of the amounts insured under all other insurances.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where this insurance is on Increased Value the following clause
                shall apply: <br />

                The agreed value of the cargo shall be deemed to be equal to the
                total amount insured under the primary insurance and all
                Increased Value insurances covering the loss and effected on the
                cargo by the Assured, and liability under this insurance shall
                be in such proportion as the sum insured herein bears to such
                total amount insured. <br />

                In the event of claim the Assured shall provide the Underwriters
                with evidence of the amounts insured under all other insurances.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on completion of unloading from the carrying vehicle or other
                conveyance in or at the final warehouse or place of storage at
                the destination named in the contract of insurance.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                on completion of unloading from the carrying vehicle or other
                conveyance in or at any other warehouse or place of storage,
                whether prior to or at the destination named in the contract of
                insurance, which the Assured or their employees elect to use
                either for storage other than in the ordinary course of transit
                or for allocation or distribution, or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                When the Assured or their employees elect to use any carrying
                vehicle or other conveyance or any container for storage other
                than in the ordinary course of transit or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                On the expiry of 60 days after completion of discharge overside
                of the subject-matter insured from the oversea vessel at the
                final port of discharge, whichever shall first occur
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If, after discharge overside from the oversea vessel at the
                final port of discharge, but prior to termination of this
                insurance, the subject-matter insured is to be forwarded to a
                destination than that to which it is insured, this insurance,
                whilst remaining subject to termination as provided in Clauses
                8.1.1 to 8.1.4, shall not extend beyond the time the
                subject-matter insured is first moved for the purpose of the
                commencement of transit to such other destination.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                This insurance shall remain in force (subject to termination as
                provided for in Clause 8.1.1 to 8.1.4 above and to the
                provisions of Clause 9 below) during delay beyond the control of
                the Assured, any deviation, forced discharge, reshipment or
                transshipment and during any variation of the adventure arising
                from the exercise of a liberty granted to carriers under the
                contract of carriage
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The insurance against the risks of mines and derelict torpedoes,
                floating or submerged, is extended whilst the subject-matter
                insured or any part thereof is on craft whilst to or from the
                oversea vessel, but in no case beyond the expiry of 60 days
                after discharge from the oversea vessel unless otherwise
                specially agreed by the insurers.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to prompt notice to insurers, and to an additional
                premium if required, this insurance shall remain in force within
                the provisions of these Clauses during any deviation, or any
                variation of the adventure arising from the exercise of a
                liberty granted to carriers under the contract of carriage. (For
                the purpose of Clause 5) <b></b> “arrival” shall be deemed to
                mean that the vessel is anchored, moored or otherwise secured at
                a berth or occurred within the harbor Authority area. If such a
                berth or place is not available, arrival is deemed to have
                occurred when the vessel first anchors, mores or otherwise
                secures either at or off the intended port or place of discharge
                <b></b>
                “Oversea vessel” shall be deemed to mean a vessel carrying the
                subject-matter from one port or place to another where such
                voyage involves a sea passage by that vessel)
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px">
          <b>Termination of Contract of Carriage</b>
        </p>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 10px">
            if owing to circumstances beyond the control of the Assured either
            the contract of carriage is terminated at a port or place other than
            the destination named therein or the transit is otherwise terminated
            before unloading of the subject-matter insured as provided for in
            Clause 8 above, then this insurance shall also terminate unless
            prompt notice is given to the insurers and continuation of cover is
            requested when this insurance shall remain in force, subject to an
            additional premium if required by the insurers, either
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p>
                until the subject-matter insured is sold and delivered at such
                port or place, or, unless otherwise specially agreed, until the
                expiry of 60 days after arrival of the subject-matter insured at
                such or place, whichever shall first occur, Or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                if the subject-matter insured is forwarded within the said
                period of 60 days (or any agreed extension thereof) to the
                destination named in the contract of insurance or to any other
                destination, until terminated in accordance with the provisions
                of Clause 5 above.
              </p>
            </div>
          </div>
        </div>

        <p style="padding-left: 0px"><b>Change of Voyage</b></p>

        <div style="display: flex">
          <p>7</p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where, after attachment of this insurance, the destination is
                changed by the Assured, this must be notified promptly to
                insurers for rates and terms to be agreed. Should a loss occur
                prior to such agreement being obtained cover may be provided but
                only if cover would have been available at a reasonable
                commercial market rate on reasonable market terms.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                where the subject-matter insured commences the transit
                contemplated by this insurance (in accordance with Clause 5.1),
                but, without the knowledge of the Assured or their employees the
                ship sails for another destination, this insurance will
                nevertheless be deemed to have attached at commencement of such
                transit.
              </p>
            </div>
          </div>
        </div>

        <h3>BENEFIT OF INSURANCE</h3>

        <div style="display: flex">
          <p>8</p>
          <p style="padding-left: 20px"><b>Not to Inure Clause</b></p>
        </div>

        <p style="padding-left: 30px">
          This insurance shall not inure to the benefit of the carrier or other
          bailee
        </p>

        <h3>MINIMISING LOSSES</h3>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 30px"><b>Duty of Assured Clause</b></p>
        </div>

        <p style="padding-left: 40px">
          It is the duty of the Assured and their servants and agents in respect
          of loss recoverable hereunder
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting or minimizing such loss and
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly reserved and exercised and the
                Underwriters will, in addition to any loss recoverable
                hereunder, reimburse the Assured for any charges properly and
                reasonably incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 20px"><b>Waiver Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            Measures taken by the Assured or the Underwriters with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of eights of either party.
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 20px"><b>Reasonable Dispatch Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            <b
              >It is a condition of this insurance that the Assured shall act
              with reasonable dispatch in all circumstances within their
              control.</b
            >
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            <b>English Law and Practice Clause</b>
          </p>
        </div>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 20px">
            This insurance is subject to English law and practice
          </p>
        </div>

        <p>
          <b>
            NOTE: - It is necessary for the Assured when they become aware of an
            event which is ‘‘held covered’’ under this insurance to give propt
            notice to the Underwriters and the right to such cover is dependent
            upon compliance with this obligation.
          </b>
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute Strike Clauses (Bulk Oil)'
          )
        "
      >
        <h3>
          1/2/83
          <span style="padding-left: 200px"
            >(FOR USE ONLY WITH THE NEW MARINE POLICY FORM)
          </span>
        </h3>
        <h3>
          <span style="padding-left: 300px">
            INSTITUTE STRIKES CLAUSES (BULK OIL)<br />
          </span>
        </h3>

        <h3>RISKS COVERED</h3>

        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 20px"><b>Risks Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            This insurance covers, except as provided in Clauses 3 and 4 below,
            loss or damage to the subject-matter insured caused by.
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                strikers, locked-out workmen, or persons taking part in labour
                disturbances, riots or civil commotions
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                any terrorist or any person acting from a political motive.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 20px"><b>General Average Clause</b></p>
        </div>

        <p style="padding-left: 40px">
          This insurance covers general average and salvage charges, adjusted or
          determined according to the contract of affreightment and/ or the
          governing law and practice, incurred to avoid or in connection with
          the avoidance of loss from a risk covered under these clauses.
        </p>

        <h3>EXCLUSIONS</h3>

        <p style="padding-left: 40px">In no case shall this insurance cover</p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Loss damage or expense attributable to willful misconduct of the
                Assured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Ordinary leakage, ordinary loss in weight or volume, or ordinary
                wear and tear of the subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by inherent vice or nature of the
                subject-matter insured
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.4</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense proximately caused by delay, even though
                the delay be caused by a risk insured against (except expenses
                payable under Clause 2 above)
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.5</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from insolvency or financial
                default of the owners managers charterers or operators of the
                vessels
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.6</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from the absence shortage or
                witholding of labour of any description whatsoever resulting
                from any strike, lockout, labour disturbance, riot or civil
                commotion
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.7</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                any delay claim based upon loss of frustration of the voyage or
                adventure
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.8</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense arising from the use of any weapon of war
                employing atomic or nuclear fission and / or fusion or other
                like reaction or radioactive force or matter
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>3.9</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                loss damage or expense caused by war civil war revolution
                rebellion insurrection, or civil strife arising there from, or
                any hostile act by or against a belligerent power.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 20px">
            <b>Unseaworthiness or Unfitness Exclusion Clause</b>
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In no case shall this insurance cover loss damage or expense
                arising from <br />
                Unfitness Unseaworthiness of vessel or craft.<br />
                Unfitness of vessel craft or conveyance for the safe carriage of
                the subject-matter insured;<br />
                where the Assured or their servants are privy to such
                unseaworthiness or unfitness, at <br />
                the time the Subject-matter insured is loaded therein.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>4.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In no case shall this insurance cover loss damage or expense the
                Underwriters waive any branch of the implied warranties of
                seaworthiness of the ship and fitness of the ship to carry the
                subject-matter insured to destination, unless the Assured or
                their servants are privy to such unseaworthiness or unfitness.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 20px"><b>Transit Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                This insurance attaches as the subject-matter insured leaves
                tanks for the
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                purpose of loading at the place named herein for the
                commencement of the transit, continues during the ordinary
                course of transit and terminates either. as the subject-matter
                insured enters tanks on discharge to place of storage or to
                storage vessel at the destination named herein, or
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 40px">
          <div style="display: flex">
            <div>
              <p>5.1.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                5.1.2 on the expiry of 30 days after the date of arrival of the
                vessel at the destination named herein, whichever shall first
                occur.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If, after discharge from the oversea vessel into craft at the
                final port or place of discharge, but prior to the termination
                of this insurance under 5.1 above, the subject- matter insured
                or any part thereof is to be forwarded to a destination other
                than that to which it is insured hereunder, the insurance on the
                subject-matter insured or such part hereof shall not extend
                beyond the commencement of transit to such other destination,
                unless otherwise agreed by the Underwriters upon receipt of
                prompt notice from the Assured.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>5.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Subject to prompt notice being given to the underwriters and an
                additional premium if required by them, this insurance shall
                remain in force (until terminated under 5.1 or above and subject
                to the provisions of Clause below) during delay beyond the
                control of the Assured, any deviation, forced discharge,
                reshipment and during any other variation of the adventure
                provided such other variation is beyond the control of the
                Assured.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>6</p>
          <p style="padding-left: 20px">
            <b>Termination of Contract of Carriage Clause</b>
          </p>
        </div>

        <p style="padding-left: 30px">
          If owning to circumstances beyond the control of the Assured either
          the contract of carriage is terminated at a port or place other than
          the destination named therein or the transit is terminated otherwise
          than as provided in Clause 8 above. Then this insurance shall also
          terminate unless prompt notice is given to the Underwriters and
          continuation of cover is requested then the insurance shall remain in
          force, subject to an additional premium if required by the
          Underwriters, either
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Until the goods are sold and delivered at such port or place,
                or, unless otherwise specially agreed, until the expiry of 30
                days after arrival of the goods hereby insured at such port or
                place, whichever shall first occur.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>6.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If the goods are forwarded within the said period of 30days (or
                any agreed extension thereof) to the destination named herein or
                to any other destination, until terminated in accordance with
                the provisions of Clause 8 above.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 20px"><b>Change of Voyage Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>7.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where, after attachment of this insurance, the destination is
                changed by the Assured, held covered at a premium and on
                conditions to be arranged subject to prompt notice being given
                to the Underwriters.
              </p>
            </div>
          </div>
        </div>

        <h3>CLAIMS</h3>

        <div style="display: flex">
          <p>7</p>
          <p style="padding-left: 20px"><b>Insurable Interest Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In order to recover under this insurance the Assured must have
                an insurable interest in the subject-matter insured at the time
                of the loss.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>8.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                subject to 8.1 above, the Assured shall be entitled to recover
                for insured loss occurring during period covered by this
                insurance, notwithstanding that the loss occurred before the
                contract of insurance was concluded, unless the Assured were
                aware of the loss and the Underwriters were not. In order to
                recover under this insurance the Assured must have an insurable
                interest in the subject-matter insured at the time of the loss.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>9</p>
          <p style="padding-left: 20px"><b>Increased Value Clause</b></p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                If any Increased value insurance is effected by the Assured on
                the cargo insured herein the agreed value increased of the cargo
                shall be deemed to the increased to the total amount insured
                under this insurance and all increased value insurances covering
                the loss, and liability under this insurance shall be in such
                proportion as the sum insured herein bears to such total amount
                insured.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>9.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                In the event of claim the Assured shall provide the Underwriters
                with evidence of the amounts insured under all other insurances.
              </p>
            </div>
          </div>
        </div>

        <h3>CLAIMS</h3>

        <div style="display: flex">
          <p>10</p>
          <p style="padding-left: 20px"><b>Adjustment</b></p>
        </div>

        <div>
          <p style="padding-left: 30px">
            For leakage and storage recoverable under this insurance are to be
            adjusted as follows:
          </p>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                The amount recoverable shall be the proportionate insured volume
                of oil lost, to be ascertained by a comparison of the gross
                volume certified as having left tanks for loading on to the
                vessel with the gross volume certified as having been delivered
                to tanks at the termination of the transit, except that where
                the contract of sale is based on weight and not on volume the
                amount recoverable may be calculated on a weight basis from such
                certified quantities. <br />

                The term “gross volume” in this Clause 10.1 means total volume
                without deduction of sediment and water content and free water,
                except to the extent that the amount of water can be shown by
                the Assured to have increased abnormally during the insured
                transit as a result of the operation of a risk covered by this
                insurance.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Adjustment shall be made to the calculation under 10.1 above to
                eliminate any change in volume caused by variation in
                temperature and any apparent change in quantity arising from the
                use of inconsistent procedure in determining the certified
                quantities.
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>10.3</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                Where this insurance provides for an excess to be applied to
                claims for leakage or shortage, such excess shall be deemed to
                include ordinary loss in weight or volume except when caused by
                variation in temperature or settling out of water. Where there
                is no such provision, the amount recoverable in accordance with
                Clauses 10.1 and 10.2 shall be subject to reduction for any
                ordinary loss excluded by Clause 3.2 above.
              </p>
            </div>
          </div>
        </div>

        <h3>BENEFIT OF INSURANCE</h3>

        <div style="display: flex">
          <p>11</p>
          <p style="padding-left: 20px"><b>Not to Inure Clause</b></p>
        </div>

        <p style="padding-left: 30px">
          This insurance shall not inure to the benefit of the carrier or other
          bailee
        </p>

        <h3>MINIMISING LOSSES</h3>

        <div style="display: flex">
          <p>12</p>
          <p style="padding-left: 30px"><b>Duty of Assured Clause</b></p>
        </div>

        <p style="padding-left: 40px">
          It is the duty of the Assured and their servants and agents in respect
          of loss recoverable hereunder
        </p>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>12.1</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to take such measures as may be reasonable for the purpose of
                averting or minimizing such loss and
              </p>
            </div>
          </div>
        </div>

        <div style="padding-left: 20px">
          <div style="display: flex">
            <div>
              <p>12.2</p>
            </div>
            <div>
              <p style="padding-left: 10px">
                to ensure that all rights against carriers, bailees or other
                third parties are properly reserved and exercised and the
                Underwriters will, in addition to any loss recoverable
                hereunder, reimburse the Assured for any charges properly and
                reasonably incurred in pursuance of these duties.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <p>13</p>
          <p style="padding-left: 20px"><b>Waiver Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            Measures taken by the Assured or the Underwriters with the object of
            saving, protecting or recovering the subject-matter insured shall
            not be considered as a waiver or acceptance of abandonment or
            otherwise prejudice the rights of eights of either party.
          </p>
        </div>

        <h3>AVOIDANCE OF DELAY</h3>

        <div style="display: flex">
          <p>14</p>
          <p style="padding-left: 20px"><b>Reasonable Dispatch Clause</b></p>
        </div>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            <b
              >It is a condition of this insurance that the Assured shall act
              with reasonable dispatch in all circumstances within their
              control.</b
            >
          </p>
        </div>

        <h3>LAW AND PRACTICE</h3>

        <div style="display: flex">
          <p></p>
          <p style="padding-left: 40px">
            <b>English Law and Practice Clause</b>
          </p>
        </div>

        <div style="display: flex">
          <p>15</p>
          <p style="padding-left: 20px">
            This insurance is subject to English law and practice
          </p>
        </div>

        <p>
          <b>
            NOTE: - It is necessary for the Assured when they become aware of an
            event which is ‘held covered’ under this insurance to give prompt
            notice to the Underwriters and the right to such cover is dependent
            upon compliance with this obligation.
          </b>
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute Replacement Clause '
          )
        "
      >
        <h3 class="text-center">INSTITUTE REPLACEMENT CLAUSE</h3>

        <p>
          In the event of loss or a damage to any part or parts of an Insured
          Machine caused by a peril covered by the policy the sum recoverable
          shall not exceed the cost of replacement or repair of such part or
          parts plus charges for forwarding and refitting, if incurred, but
          excluding duty unless the full duty is included in payment of
          additional duty shall be recoverable. Provided always that in no case
          shall the liability of underwriters exceed the Insured value of the
          complete Machine.
        </p>
      </section>

      <section
        v-if="
          singleBusiness.addClause.includes(
            'Subject to Institute Malicious Damage Clause'
          )
        "
      >
        <h3 class="text-center">
          1/1/09
          <span>(FOR USE ONLY WITH THE NEW MARINE POLICY FORM)</span>
        </h3>

        <h3>INSTITUTE MALICIOUS DAMAGE CLAUSE</h3>

        <p>
          In consideration of an additional premium, it is hereby agreed that
          the exclusion “deliberate damage to or deliberate destruction of the
          subject matter insured or any part thereof by the wrongful act of any
          person or persons” is deemed to be deleted and further that this
          insurance covers loss of or damage to the subject matter insured
          caused by malicious acts vandalism or sabotage, subject always to the
          other exclusions contained in this insurance.
        </p>
      </section>
    </div>
  </div>
</template>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
}
</style>

<script>
import { $toWords } from "../../helpers";
import { mapState } from "pinia";
import { useMainStore } from "../../stores/main";
export default {
  props: ["singleBusiness"],
  data() {
    return {
      pluralCurrencies: ["DOLLAR", "EURO", "POUND"],
    };
  },

  computed: {
    ...mapState(useMainStore, ["user"]),
    insuredWords() {
      return $toWords.convert(
        parseInt(
          this.singleBusiness.sumInsured != null
            ? this.singleBusiness.sumInsured
            : "0"
        )
      );
    },
  },
};
</script>