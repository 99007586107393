<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="bulkPayment"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Make Bulk Payment
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="handleSubmit()">
          <div class="modal-body">
            <p>Select Certificates You Wish To Pay For</p>
            <table
              class="table table-bordered table-hover"
              id="dataTable"
              cellspacing="0"
              width="100%"
            >
              <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Certificate Number</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Premium</th>

                  <th>Select</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Policy Number</th>
                  <th>Certificate Number</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Premium</th>

                  <th>Select</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="certificate in certificates"
                  :key="certificate.insuredId"
                >
                  <td>{{ singleBusiness.policyno }}</td>
                  <td>{{ certificate.certificateNo }}</td>
                  <td>{{ certificate.from }}</td>
                  <td>{{ certificate.to }}</td>
                  <td>₦ {{ certificate.premium.toLocaleString() }}</td>

                  <td>
                    <input
                      class="form-check"
                      @change="sumPremium(certificate)"
                      :value="certificate.certificateNo"
                      v-model="data.certificateNo"
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button v-if="loading" class="btn btn-primary float-right disabled">
              <div
                class="spinner-border spinner-border-sm mx-1 text-light"
                role="status"
              ></div>
              Loading...
            </button>
            <button v-else type="sumbit" class="btn btn-primary">
              Make Payment
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
//import Swal from "sweetalert2";
import { useMarineStore } from "@/stores/marine";
import { useMainStore } from "@/stores/main";
import $config from "../../helpers/config";

export default {
  name: "Bulk Payment",
  props: ["certificates", "singleBusiness"],
  //components: { AppLayout },
  data() {
    return {
      tranrsactionreference: "",
      data: {
        certificateNo: [],
        premium: 0,
        sumInsured: 0,
      },
    };
  },
  computed: {
    ...mapState(useMarineStore, ["loading", "componentLoading"]),
    ...mapState(useMainStore, ["user"]),
  },

  methods: {
    ...mapActions(useMarineStore, ["makePayment"]),
    sumPremium(certificate) {
      if (this.data.certificateNo.includes(certificate.certificateNo)) {
        this.data.premium += certificate.premium;
        this.data.sumInsured += certificate.sumInsured;
      } else {
        this.data.premium -= certificate.premium;
        this.data.sumInsured -= certificate.sumInsured;
      }
    },

    async handleSubmit() {
      if (this.data.certificateNo.length < 1) {
        alert("You Must Select At Least One Certificate");
      } else {
        this.tranrsactionreference = `CHIMARINETXR-${Math.floor(
          Math.random() * 100000000
        )}`;

        //eslint-disable-next-line no-undef
        FlutterwaveCheckout({
          public_key: $config.flwLivePublicKey,
          tx_ref: this.tranrsactionreference,
          amount: this.data.premium,
          currency: "NGN",
          payment_options: "card, banktransfer, ussd",
          callback: () => {
            this.makePayment({
              certificateNo: this.data.certificateNo,
              startDate: this.certificates[0].startDate,
              policyno: this.singleBusiness.policyno,
              coverScope: "MARINE OPEN COVER",
              sumInsured: this.data.sumInsured,
              premium: this.data.premium,
              premiumRate: this.certificates[0].premiumRate,
              endorsement: this.certificates[0].endorsement,
              submittedBy: "Marine Portal",
              transactionReference: this.tranrsactionreference,
            });
          },
          meta: {
            broker_id: this.user.brokerId,
          },
          customer: {
            email: this.user.email,
            name: this.user.broker,
          },
          customizations: {
            title: "Consolidated Hallmark Insurance",
            description: "Payment for marine insurance",
            logo: "https://s3-symbol-logo.tradingview.com/consolidated-hallmark-insurance-plc--600.png",
          },
        });
      }
    },
  },
};
</script>