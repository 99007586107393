<template>
  <!--Print Certificate-->

  <div
    v-show="false"
    id="certificate2"
    style="
    width: 960px;
    margin: 0 auto;
    border: 2px solid #990100;
    padding: 40px;
  "
  >
    <!-- <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
      "
    ></div> -->
    <div style="display: flex; justify-content: space-between">
      <div class="mt-4">
        <img
          class="my-2"
          style="width: 400px"
          src="@/assets/certlogo.png"
          alt=""
        />
        </div>
        <div class="mt-4">
        <div>
          <p>266, Ikorodu Road, Obanikoro, Lagos</p>
          <p>Tel: +234-1-2912543</p>
          <p>Fax: +234-1-4618380</p>
          <p style="text-decoration: underline">Email: info@chiplc.com</p>
        </div>
      </div>
    </div>
    <div>
      <h2 class="my-2" style="text-align: center">
        MARINE INSURANCE CERTIFICATE
      </h2>
      <div style="display: grid; grid-template-columns: 1fr 1fr">
        <div>
          <p>
            <b>Sum Insured:</b>
            {{
              singleBusiness.sumInsured != null
                ? parseInt(singleBusiness.sumInsured).toLocaleString("en-US")
                : ""
            }}
            Naira Only
          </p>
          <p>
            <b>Premium:</b>
            {{
              singleBusiness.premium != null
                ? parseInt(singleBusiness.premium).toLocaleString("en-US")
                : ""
            }}
            Naira Only
          </p>
          <p>
            <b>Proforma Inv No:</b>
            {{
              singleBusiness.proformaInvoiceNo
            }}
          </p>
        </div>
        <div style="padding-left: 200px">
          <p><b>CHI NO:</b> {{ singleBusiness.certifateNo }}</p>
          <p>
            <b>Effective Date:</b>
            {{
              singleBusiness.startDate != null
                ? singleBusiness.startDate.split("T")[0]
                : ""
            }}
          </p>
          <p><b>Rate:</b> {{ singleBusiness.premiumRate }}%</p>
        </div>
      </div>
    </div>
    <div class="my-4">
      <p>
        It is hereby certified that
        <b>CONSOLIDATED HALLMARK INSURANCE PLC</b> has issued this cerificate
        under
      </p>
      <div class="d-flex" style="justify-content:space-between">
      <div class="px-2">
      <p><b>Poloicy No:</b> {{ singleBusiness.policyno }}</p>
      <p><b>TIN No:</b> {{ singleBusiness.tin }}</p>
      <p><b>In Favour Of:</b> {{ singleBusiness.insuredName }}</p>
      <p><b>From:</b> {{ singleBusiness.address }}</p>
      <p>
        <b>For The Sum Of:</b>
        {{ insuredWords }} Naira Only
      </p>
      <p><b>Nature Of Cargo: </b> {{ singleBusiness.natureofCargo }} </p>
      <p><b>Mode Of Parking: </b> {{ singleBusiness.parkingType }} </p>
    </div>
      <div class="px-2">
      <p><b>Vessel :</b> ANY APPROVED STEAMER(S) AS PER NIGERIAN CLASSIFICATION CLAUSE</p>
      <p><b>Conveyance :</b> {{ singleBusiness.conveyance }}</p>
      <p><b>Voyage:</b> {{ singleBusiness.voyageType }}</p>
      <p>
        <b>Destination : </b> From <b>{{ singleBusiness.from }}</b> to
        <b>{{ singleBusiness.to }}</b>
      </p>
      <p><b>Interests Insured: </b> {{ singleBusiness.cargoDescription }} AS PER PROFORMA INV.NO {{ singleBusiness.proformaInvoiceNo }} Dated {{
              singleBusiness.startDate != null
                ? singleBusiness.startDate.split("T")[0]
                : ""
            }}</p>
      
    </div>
  </div>
      <p><b>Lien Clause:</b> IT IS HEREBY DECLARED AND AGREED THAT LOSS IF ANY PAYABLE UNDER THIS POLICY IS PAYABLE TO ........ AS THEIR INTEREST MAY APPEAR WHOSE RECEIPT WILL BE A VALID DISCHARGE. </p>
      <p><b>Conditions:</b> SUBJECT TO NO LOSS AS AT {{ singleBusiness.startDate.split("T")[0]
        }}<br />
        SUBJECT TO
        {{
          singleBusiness.typeofCover == "ICC'A'"
            ? "INSTITUTE CARGO CLUSE A"
            : singleBusiness.typeofCover == "ICC'B'"
            ? "INSTITUTE CARGO CLUSE B"
            : singleBusiness.typeofCover == "ICC'C'"
            ? "INSTITUTE CARGO CLUSE C"
            : singleBusiness.typeofCover == "IFFC'A'"
            ? "INSTITUTE FROZEN FOOD CLUSE A"
            : singleBusiness.typeofCover == "IFFC'C'"
            ? "INSTITUTE FROZEN FOOD CLUSE C"
            : singleBusiness.typeofCover == "IBOC"
            ? "INSTITUTE BULK OIL CLUSE"
            : singleBusiness.typeofCover == "ICC(Air)"
            ? "INSTITUTE CARGO CLUSE AIR"
            : ""
        }}<br />
        SUBJECT TO NIGERIAN CLASSIFICATION CLAUSE<br />
        SUBJECT TO PRIVATE JETTY WARRANTY<br />
        SUBJECT TO CANCELLATION WARRANTY<br />
        SUBJECT TO CLAIM NOTIFICATION CLAUSE<br />
        WARRANTED GOODS BE PROFESSIONALLY PACKED<br />
        SUBJECT TO EXCESS OF N50,000.00 OR 10% OF CLAIM WHICHEVER IS HIGHER<br />
        COVER TERMINATES IMMEDIATELY AFTER DISCHARGE OF CARGO FROM THE
        <br />
        CARRYING VESSEL AT APAPA PORT, LAGOS.<br />
        EXCLUDING OXIDATION & DISCOLORATION, ELECTRICAL & MECHANICAL
        <br />
        DERANGEMENT WATER DAMAGE (EXCEPT SEA, RIVER & LAKE WATER),<br />
        INFESTATION, ORDINARY LEAKAGE, ORDINARY LOSS IN WEIGHT OR VOLUME,
        
        WEAR & TEAR, SWEAT OR TAINT DAMAGE, CONTRA-BAND GOODS SUCH AS,
        
        FIRE ARMS, NARCOTICS, OPIUM AND OTHER DANGEROUS GOODS
      </p>
     

      <p>
        In the event of loss or damage which may give rise to a claim under this
        certificate, notice must be given immediately to CONSOLIDATED HALLMARK
        INSURANCE PLC, 266 IKORODU ROAD, OBANIKORO, LAGOS.
      </p>
      <p>
        Any unlawful claim shall be settled by CONSOLIDATED HALLMARK INSURANCE
        PLC upon surrender of this cerificate together with supporting documents
        in accordance with instruction.se
      </p>
    </div>
    <div
      style="display: flex; justify-content: space-between; "
    >
      <div>
        <img style="width: 150px" src="@/assets/signature2.png" />
        <p><b>................................................</b></p>
        <h3>Authorized Signatory</h3>
      </div>
      <div>
        <img src="@/assets/signature1.png" />
        <!-- <p><b>................................................</b></p>
        <h3>Examined</h3> -->
      </div>
    </div>

    <div>
      <p style="page-break-before: always"></p>
      <br />
      <br />
      <br />
      <h2 class="my-4" style="text-align: center; color: #990100">
        <b>IMPORTANT</b>
      </h2>
      <h3 class="my-4" style="text-align: center">
        <b
          >PROCEDURE IN THE EVENT OF LOSS OR DAMAGE FOR WHICH UNDERWRITERS MAY
          BE LIABLE</b
        >
      </h3>

      <h3 class="my-4" style="text-align: center">
        LIABILITY OF CARRIERS BAILEES OR OTHER THIRD PARTIES
      </h3>

      <p>
        It is the duty of the Assured and their Agents, in all cases to take
        such measure as may be reasonable for the purpose of averting or
        minimizing a loss and to ensure that all rights against Carriers Bailees
        or Other Third Parties are properly preserved and exercised. In
        particular, the Assured or their Agent are required:-
      </p>

      <div>
        <div style="display: flex">
          <p>1</p>
          <p style="padding-left: 50px">
            To claim immediately on the Carriers, Port Authorities or other
            Bailees for any missing packages.
          </p>
        </div>
      </div>

      <div>
        <div style="display: flex">
          <p>2</p>
          <p style="padding-left: 50px">
            In no circumstances except under written protest to give clean
            receipts where goods are in doubtful condition.
          </p>
        </div>
      </div>

      <div>
        <div style="display: flex">
          <p>3</p>
          <p style="padding-left: 50px">
            When delivery is made by container, to ensure that the container and
            the seals are examined immediately by their responsible official. If
            the container is delivered damaged or with seals broken or missing
            or with seals other than as stated in the shipping documents, to
            clause the delivery receipt accordingly and retain all defective or
            irregular seals for subsequent identification.
          </p>
        </div>
      </div>

      <div>
        <div style="display: flex">
          <p>4</p>
          <p style="padding-left: 50px">
            To apply immediately for survey by Carriers’ or other Bailees
            Representatives if any loss or damage be apparent and claim on the
            Carriers or other Bailees for any actual loss or damage found at
            such Survey.
          </p>
        </div>
      </div>

      <div>
        <div style="display: flex">
          <p>5</p>
          <p style="padding-left: 50px">
            To give notice in writing to the Carriers or other Bailees within 3
            days of delivery if the loss or damage was not found apparent at the
            time of taking delivery.
          </p>
        </div>
      </div>

      <h3>
        <b>
          NOTE: The consignees or their Agents are recommended to make
          themselves familiar with the regulations of the port Authorities at
          the port of discharge.
        </b>
      </h3>

      <h3 class="my-4">DOCUMENTATION OF CLAIMS</h3>

      <p>
        To enable claims to be dealt with promptly, the Assured or their Agents
        are advised to submit all available supporting documents without delay,
        including when applicable:-
      </p>

      <div style="display: flex">
        <p>1</p>
        <p style="padding-left: 50px">
          Original policy or certificate of insurance.
        </p>
      </div>

      <div style="display: flex">
        <p>2</p>
        <p style="padding-left: 50px">
          Original or copy of shipping specification and /or weight notes.
        </p>
      </div>

      <div style="display: flex">
        <p>3</p>
        <p style="padding-left: 50px">
          Original Bill of lading and / or other contract of carriage.
        </p>
      </div>

      <div style="display: flex">
        <p>4</p>
        <p style="padding-left: 50px">
          Survey report or other documentary evidence to show the extent of the
          loss or damage.
        </p>
      </div>

      <div style="display: flex">
        <p>5</p>
        <p style="padding-left: 50px">
          Landing account and weight notes at final destination
        </p>
      </div>

      <div style="display: flex">
        <p>6</p>
        <p style="padding-left: 50px">
          Correspondence exchanged with the Carriers and other parties regarding
          their liability for the loss or damage.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
@font-face {
font-family: "Book-Antiqua";
src: url("../../assets/BookAntiquaFont.ttf");
}
div{
  font-family: Book-Antiqua;
}

</style>

<script>
import { $toWords } from "../../helpers";

export default {
  props: ["singleBusiness"],
  data() {
    return {
      pluralCurrencies: ["DOLLAR", "EURO", "POUND"],
    };
  },
  computed: {
    insuredWords() {
      return $toWords.convert(
        parseInt(
          this.singleBusiness.sumInsured != null
            ? this.singleBusiness.sumInsured
            : "0"
        )
      );
    },
  },
};
</script>